import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch, FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProjectServices.css";

const ProjectServices = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({ id: null, title: "", description: "", imageBase64: null });
  const [previewImage, setPreviewImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level

  // Fetch projects from API
  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get("/api/projects", {
        params: {
          page: currentPage - 1,
          size: pageSize,
          keyword: searchTerm.trim(),
        },
      });

      const { content, totalPages } = response.data;
      setProjects(Array.isArray(content) ? content : []);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error("Error fetching projects:", error.response?.data || error.message);
      toast.error("Failed to load projects.");
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage, pageSize]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        toast.error("File size exceeds 10MB.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;

        if (base64String.startsWith("data:image")) {
          setFormData({ ...formData, imageBase64: base64String }); // Store the full string in formData
          setPreviewImage(base64String); // For preview
        } else {
          toast.error("Invalid image format");
          console.error("Invalid Base64 string");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Add or edit project
  const handleAddEditProject = async () => {
    if (!formData.title.trim() || !formData.description.trim()) {
      toast.error("Title and description are required.");
      return;
    }

    try {
      if (isEditing) {
        await axiosInstance.put(`/api/projects/${formData.id}`, formData);
        toast.success("Project updated successfully!");
      } else {
        await axiosInstance.post("/api/projects", formData);
        toast.success("Project added successfully!");
      }
      resetForm();
      fetchProjects();
    } catch (error) {
      console.error("Error saving project:", error.response?.data || error.message);
      toast.error(error.response?.data?.message || "Failed to save project.");
    }
  };

  // Set Form Data for Editing
  const handleEditClick = (project) => {
    setFormData({
      id: project.id,
      title: project.title,
      description: project.description,
      imageBase64: project.image ? `data:${project.imageContentType};base64,${project.image}` : null,
    });
    setPreviewImage(project.image ? `data:${project.imageContentType};base64,${project.image}` : null);
    setIsEditing(true);
    setShowForm(true);
  };

  // Delete a project
  const handleDeleteProject = async (id) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await axiosInstance.delete(`/api/projects/${id}`);
        toast.success("Project deleted successfully!");
        fetchProjects();
      } catch (error) {
        console.error("Error deleting project:", error.response?.data || error.message);
        toast.error("Failed to delete project.");
      }
    }
  };

  // Reset the form
  const resetForm = () => {
    setFormData({ id: null, title: "", description: "", imageBase64: null });
    setPreviewImage(null);
    setIsEditing(false);
    setShowForm(false);
    setZoomLevel(1); // Reset zoom level
  };

  // Search projects
  const handleSearch = () => {
    setCurrentPage(1);
    fetchProjects();
  };

  // Handle Zoom In
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); // Max zoom level: 3x
  };

  // Handle Zoom Out
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); // Min zoom level: 1x
  };

  return (
    <div className="project-services-container">
      <ToastContainer />
      <h1>Manage Projects</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch} className="search-button">
            <FaSearch />
          </button>
        </div>
        <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
        <button onClick={() => setShowForm(true)} className="add-button">
          <FaPlus /> Add Project
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleInputChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
          ></textarea>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          {previewImage && (
            <div className="image-preview-container">
              <img
                src={previewImage}
                alt="Preview"
                className="image-preview"
                style={{ transform: `scale(${zoomLevel})` }}
              />
              <div className="zoom-controls">
                <button onClick={handleZoomIn} className="zoom-button">
                  <FaSearchPlus />
                </button>
                <button onClick={handleZoomOut} className="zoom-button">
                  <FaSearchMinus />
                </button>
              </div>
            </div>
          )}
          <button onClick={handleAddEditProject} className="form-submit-button">
            {isEditing ? "Update Project" : "Add Project"}
          </button>
          <button onClick={resetForm} className="cancel-button">
            Cancel
          </button>
        </div>
      )}

      {/* Projects Table */}
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.length > 0 ? (
            projects.map((project) => (
              <tr key={project.id}>
                <td>{project.title}</td>
                <td>{project.description}</td>
                <td>
                  {project.image && (
                    <img
                      src={`data:${project.imageContentType};base64,${project.image}`}
                      alt={project.title}
                      className="project-image"
                    />
                  )}
                </td>
                <td>
                  <button onClick={() => handleEditClick(project)} className="edit-button">
                    <FaEdit /> Edit
                  </button>
                  <button onClick={() => handleDeleteProject(project.id)} className="delete-button">
                    <FaTrash /> Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No projects available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProjectServices;
