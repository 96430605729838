import React from "react";
import "./BannerSection.css";

const BannerSection = ({ handleNavigate }) => (
  <section className="banner-section">
    <div className="banner-content">
      <div className="banner-text">
        <h1>Transforming Ideas into Reality</h1>
        <p>
          Partner with Encoders Software to bring your innovative concepts to life.
          Let’s collaborate to create exceptional IT solutions tailored for your business.
        </p>
        <p>
          With a track record of delivering successful projects across multiple industries, we are
          committed to excellence, reliability, and customer satisfaction. Our solutions are designed
          to empower businesses, enhance productivity, and foster long-term growth.
        </p>
        <ul className="banner-highlights">
          <li>✔ Cutting-edge Technology Solutions</li>
          <li>✔ Experienced and Dedicated Team</li>
          <li>✔ Customized Business Strategies</li>
          <li>✔ 24/7 Customer Support</li>
        </ul>
        <button onClick={handleNavigate} className="banner-button">
          Contact Us
        </button>
      </div>
     
    </div>
  </section>
);

export default BannerSection;