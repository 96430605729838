import React from 'react';
import { FaSyncAlt, FaDownload, FaFilePdf, FaFileExcel, FaFileCsv, FaFileCode } from 'react-icons/fa';

/**
 * Admin Controls Component
 * @param {Object} props - Props passed from parent component
 */
const AdminControls = ({
  fetchData,
  statusFilter,
  setStatusFilter,
  updateAtFilter,
  setUpdateAtFilter,
  selectedColumns,
  setSelectedColumns,
  allColumns,
  downloadCSV,
  downloadPDF,
  downloadExcel,
  downloadJSON,
  graphTypes,
  selectedGraphs,
  setSelectedGraphs,
}) => {
  return (
    <div className="admin-controls">
      {/* Data Load Button */}
      <div className="control-section">
        <button onClick={fetchData} className="action-button refresh">
          <FaSyncAlt /> Load Data
        </button>
      </div>

      {/* Status Filter */}
      <div className="control-section">
        <label>Status Filter:</label>
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="ALL">All</option>
          <option value="Pending">Pending</option>
          <option value="Accepted">Accepted</option>
          <option value="Declined">Declined</option>
        </select>
      </div>

      {/* Updated At Filter */}
      <div className="control-section">
        <label>Updated At:</label>
        <input
          type="date"
          value={updateAtFilter}
          onChange={(e) => setUpdateAtFilter(e.target.value)}
        />
      </div>

      {/* Column Selector */}
      <div className="control-section column-selector">
        <label>Select Columns:</label>
        <div className="column-list">
          {allColumns.map((col) => (
            <div key={col} className="column-item">
              <input
                type="checkbox"
                checked={selectedColumns.includes(col)}
                onChange={() => {
                  setSelectedColumns((prev) =>
                    prev.includes(col) ? prev.filter((c) => c !== col) : [...prev, col]
                  );
                }}
              />
              {col}
            </div>
          ))}
        </div>
      </div>

      {/* Graph Selector */}
      <div className="control-section graph-selector">
        <label>Select Graphs:</label>
        <div className="graph-list">
          {graphTypes.map((type) => (
            <div key={type} className="graph-item">
              <input
                type="checkbox"
                checked={selectedGraphs.includes(type)}
                onChange={() => {
                  setSelectedGraphs((prev) =>
                    prev.includes(type) ? prev.filter((g) => g !== type) : [...prev, type]
                  );
                }}
              />
              {type}
            </div>
          ))}
        </div>
      </div>

      {/* Export Buttons */}
      <div className="control-section export-buttons">
        <button onClick={downloadCSV} className="action-button csv">
          <FaFileCsv /> Export CSV
        </button>
        <button onClick={downloadPDF} className="action-button pdf">
          <FaFilePdf /> Export PDF
        </button>
        <button onClick={downloadExcel} className="action-button excel">
          <FaFileExcel /> Export Excel
        </button>
        <button onClick={downloadJSON} className="action-button json">
          <FaFileCode /> Export JSON
        </button>
      </div>
    </div>
  );
};

export default AdminControls;
