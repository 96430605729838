import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axiosInstance from "./axiosInstance";
import { FaReply, FaPaperPlane, FaArrowDown } from "react-icons/fa";
import "./CommentSection.css";
import { useNavigate } from "react-router-dom"; // Import navigate hook

import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FaWhatsapp,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";

const CommentSection = ({ blogId, userEmail }) => {
  const [comments, setComments] = useState([]); // List of comments
  const [newComment, setNewComment] = useState(""); // For new comment
  const [replyCommentId, setReplyCommentId] = useState(null); // ID of comment being replied to
  const [replyText, setReplyText] = useState(""); // For reply input
  const [loading, setLoading] = useState(false); // Loading indicator
  const [page, setPage] = useState(0); // Current page for pagination
  const [totalPages, setTotalPages] = useState(0); // Total number of pages

  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("accessToken");

  const currentUrl = window.location.href; // For sharing purposes

  // ✅ Fetch Comments with Pagination
  const fetchComments = async (pageNumber = 0) => {
    setLoading(true);
    try {
      console.log("Fetching comments for blogId:", blogId);
      const response = await axiosInstance.get(`/api/user/blogs/${blogId}/replies`, {
        params: {
          page: pageNumber,
          size: 5,
          sortBy: "createdAt",
          sortOrder: "desc",
        },
      });
  
      console.log("API Response:", response.data);
  
      if (response.data?.data?.content) {
        setComments((prev) =>
          pageNumber === 0
            ? response.data.data.content
            : [...prev, ...response.data.data.content]
        );
        setTotalPages(response.data.data.totalPages || 0);
        setPage(response.data.data.pageable?.pageNumber || pageNumber);
      } else {
        console.warn("Unexpected API Response Format:", response.data);
        alert("Failed to fetch comments. Unexpected response format.");
      }
    } catch (error) {
      console.error("Error fetching comments:", error.response?.data || error.message);
     
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchComments();
  }, [blogId]);


  const handleAddComment = async () => {

    if (!isLoggedIn) {
      navigate("/LoginSignup", { state: { redirectTo: `/BlogsUsers` } });
      return;
    }

    if (!newComment.trim()) {
      alert("Comment cannot be empty!");
      return;
    }
  
    try {
      const response = await axiosInstance.post(`/api/user/blogs/${blogId}/replies`, {
        content: newComment,
        author: localStorage.getItem("email") || "Anonymous",
      });
  
      if (response.data && response.data.id) {
        // ✅ Update comments state with the new comment
        setComments((prev) => [
          {
            id: response.data.id,
            content: response.data.content,
            author: response.data.author,
            createdAt: response.data.createdAt,
            replies: [],
          },
          ...prev,
        ]);
        setNewComment(""); // Clear input field
      } else {
        console.error("Invalid API response:", response.data);
        alert("Failed to add comment. Invalid response from server.");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  // ✅ Add Reply to a Comment
  const handleAddReply = async (commentId) => {
    if (!replyText.trim()) {
      alert("Reply cannot be empty!");
      return;
    }
    try {
      const response = await axiosInstance.post(`/api/user/blogs/${blogId}/replies`, {
        content: replyText,
        author: userEmail || "Anonymous",
        parentId: commentId,
      });

      if (response.data && response.data.id) {
        setComments((prev) =>
          prev.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: [
                    ...comment.replies,
                    {
                      id: response.data.id,
                      content: response.data.content,
                      author: response.data.author,
                      createdAt: response.data.createdAt,
                    },
                  ],
                }
              : comment
          )
        );
        setReplyText("");
        setReplyCommentId(null);
      }
    } catch (error) {
      console.error("Error adding reply:", error);
      alert("Failed to add reply. Please try again.");
    }
  };

  // ✅ Load More Comments
  const handleLoadMore = () => {
    if (page + 1 < totalPages) {
      fetchComments(page + 1);
    }
  };

  return (
    <div className="comment-section">


           {/* ✅ Share Options */}
           <div className="share-section">
        <h4>🔗 Share This Blog</h4>
        <div className="share-buttons">
          <WhatsappShareButton url={currentUrl} title="Check out this blog!">
            <FaWhatsapp className="share-icon" />
          </WhatsappShareButton>
          <EmailShareButton url={currentUrl} subject="Interesting Blog!" body="Check out this blog:">
            <FaEnvelope className="share-icon" />
          </EmailShareButton>
          <FacebookShareButton url={currentUrl} quote="Check out this blog!">
            <FaFacebook className="share-icon" />
          </FacebookShareButton>
          <TwitterShareButton url={currentUrl} title="Interesting Blog">
            <FaTwitter className="share-icon" />
          </TwitterShareButton>
        </div>
      </div>
      <h3>💬 Comments</h3>

      {/* ✅ Add New Comment */}
      <div className="add-comment">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write your comment here..."
        />

        
        <button className="comment-btn" onClick={handleAddComment}>
          <FaPaperPlane /> Post Comment
        </button>
      </div>

 

      {/* ✅ Display Comments */}
      <div className="comments-list">
  {loading ? (
    <p>Loading comments...</p>
  ) : comments.length > 0 ? (
    comments.map((comment) => (
      <div key={comment.id} className="comment">
        <p>
          <strong>{comment.author}:</strong> {comment.content}
        </p>
        <small>Posted on: {new Date(comment.createdAt).toLocaleString()}</small>
      </div>
    ))
  ) : (
    <p>No comments yet. Be the first to comment!</p>
  )}
</div>

      {/* ✅ Load More Button */}
      {page + 1 < totalPages && (
        <button className="load-more-btn" onClick={handleLoadMore}>
          <FaArrowDown /> Load More
        </button>
      )}
    </div>
  );
};

CommentSection.propTypes = {
  blogId: PropTypes.number.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default CommentSection;
