import axios from "axios";
import config from "../../include/config";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config.BASE_URL, // Base URL for all API requests
  headers: {
    "Content-Type": "application/json", // Default content type
  },
  withCredentials: true, // Send cookies along with requests
});

// Request Interceptor for attaching JWT Token
axiosInstance.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token"); // Check sessionStorage first

    if (!token) {
      token = localStorage.getItem("accessToken"); // Fallback to localStorage if not in sessionStorage
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach the JWT token
    }

    return config;
  },
  (error) => {
    console.error("Request Interceptor Error:", error);
    return Promise.reject(error);
  }
);

// Response Interceptor for handling errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // Pass valid responses through
  (error) => {
    if (error.response) {
      // Handle specific HTTP error codes
      switch (error.response.status) {
        case 401:
          console.warn("Unauthorized: Redirecting to login.");
          // Optional: Perform logout or redirect
          break;
        case 403:
          console.warn("Forbidden: You don't have permission.");
          break;
        case 500:
          console.error("Server Error: Please try again later.");
          break;
        default:
          console.error(`Error: ${error.response.status}`);
      }
    } else if (error.request) {
      // Network or server did not respond
      console.error("No response from the server:", error.request);
    } else {
      console.error("Request Setup Error:", error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
