import React, { useEffect, useState } from 'react';
import axiosInstance from "./axiosInstance";
import './ContactDetails.css';
import config from "../../include/config";

const ContactDetails = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch all contacts
  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${config.BASE_URL}/api/contact/list?page=0&size=10`);
      setContacts(response.data.content);
    } catch (err) {
      setError('Failed to fetch contact details');
    } finally {
      setLoading(false);
    }
  };

  // Delete a contact
  const deleteContact = async (id) => {
    try {
      await axiosInstance.delete(`${config.BASE_URL}/api/contact/${id}`);
      setContacts(contacts.filter((contact) => contact.id !== id));
    } catch (err) {
      setError('Failed to delete contact');
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="contact-container">
      <h1 className="title">Contact Details</h1>
      {contacts.length === 0 ? (
        <p className="no-contacts">No contacts found.</p>
      ) : (
        <table className="contact-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Company</th>
              <th>Message</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.mobile}</td>
                <td>{contact.company}</td>
                <td>{contact.message}</td>
                <td>
                  <button
                    className="delete-btn"
                    onClick={() => deleteContact(contact.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ContactDetails;
