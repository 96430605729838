import React, { useState } from "react";
import PropTypes from "prop-types";
import "./BlogCard.css";

const BlogCard = ({ blog, onClick, detailed }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={`blog-card ${detailed ? "detailed" : ""}`}
      onClick={!detailed ? onClick : null}
    >
      {/* ✅ Blog Image with Lazy Loading */}
      <div className="blog-image-container">
        {!imageLoaded && <div className="image-loader"></div>}
        <img
          src={`data:image/png;base64,${blog.image}`}
          alt={blog.title}
          className={`blog-image ${imageLoaded ? "loaded" : ""}`}
          loading="lazy"
          onLoad={() => setImageLoaded(true)}
        />
      </div>

      {/* ✅ Blog Content */}
      <div className="blog-content">
        <h3 className="blog-title">{blog.title}</h3>
        <p className="blog-author">
          <strong>Author:</strong> {blog.author}
        </p>
        <p className="blog-description">
          {detailed ? blog.content : `${blog.content.substring(0, 100)}...`}
        </p>
        {!detailed && (
          <button
            className="read-more-btn"
            onClick={(e) => {
              e.stopPropagation();
              onClick(blog);
            }}
          >
            Read More
          </button>
        )}
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  blog: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  detailed: PropTypes.bool,
};

BlogCard.defaultProps = {
  onClick: () => {},
  detailed: false,
};

export default React.memo(BlogCard);
