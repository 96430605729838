import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "./axiosInstance";
import { FaSearch } from "react-icons/fa";
import CategoryList from "./CategoryList";
import BlogCard from "./BlogCard";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import CommentSection from "./CommentSection";
import Slider from "../../include/Slider";
import "./BlogsUsers.css";
import _ from "lodash"; // Lodash for debounce

const BlogsUsers = () => {
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  // Fetch Categories
  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/user/blogs/categories");
      setCategories(response.data.content || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch Blogs
  const fetchBlogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/user/blogs", {
        params: { page: currentPage, size: 6, search: searchQuery, categoryId: selectedCategory },
      });
      setBlogs((prevBlogs) =>
        currentPage === 0 ? response.data.content : [...prevBlogs, ...response.data.content]
      );
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchQuery, selectedCategory]);

  useEffect(() => {
    fetchCategories();
    fetchBlogs();
  }, [fetchCategories, fetchBlogs]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setCurrentPage(0);
    setBlogs([]);
    setSelectedBlog(null);
  };

  const debounceSearch = useCallback(
    _.debounce((query) => {
      setSearchQuery(query);
      setCurrentPage(0);
      setBlogs([]);
      setSearching(false);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    setSearching(true);
    debounceSearch(e.target.value);
  };

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };

  const handleBackToList = () => {
    setSelectedBlog(null);
  };

  const handleLoadMore = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <Navbar />
      <Slider />
      <div className="blogs-container">
        <h1>📝 Encoders Software Blogs</h1>
        <div className="layout">
          <CategoryList
            categories={categories}
            onCategoryClick={handleCategoryClick}
            selectedCategory={selectedCategory}
          />

          <div className="main-content">
            {loading && currentPage === 0 && (
              <div className="loader-container" role="status">
                <div className="loader"></div>
                <p>Loading blogs...</p>
              </div>
            )}

            {!loading && selectedBlog ? (
              <div className="blog-detail">
                <button onClick={handleBackToList} className="back-btn">
                  ← Back to Blogs
                </button>
                <BlogCard blog={selectedBlog} detailed />
                <CommentSection blogId={selectedBlog.id} />
              </div>
            ) : (
              !loading && (
                <>
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search blogs..."
                      onChange={handleSearch}
                      aria-label="Search blogs"
                    />
                    <button>
                      <FaSearch />
                    </button>
                  </div>

                  {searching ? (
                    <div className="loader-container" role="status">
                      <div className="loader"></div>
                      <p>Searching blogs...</p>
                    </div>
                  ) : (
                    <>
                      <div className="blogs-grid">
                        {blogs.map((blog) => (
                          <BlogCard
                            key={blog.id}
                            blog={blog}
                            onClick={() => handleBlogClick(blog)}
                          />
                        ))}
                      </div>

                      {currentPage + 1 < totalPages && blogs.length > 0 && (
                        <button className="load-more-btn" onClick={handleLoadMore}>
                          Load More
                        </button>
                      )}

                      {!loading && blogs.length === 0 && (
                        <p className="no-blogs">No blogs found. Try a different search or category.</p>
                      )}
                    </>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogsUsers;
