import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          At <strong>Encoders Software Pvt. Ltd.</strong>, we prioritize your privacy and are fully committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and safeguard your data when you interact with our website, products, and services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We collect the following types of information:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, company name, and billing details.</li>
          <li><strong>Non-Personal Information:</strong> IP address, browser type, device information, and website usage data.</li>
          <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies to enhance your browsing experience.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>
          The information we collect is used to:
        </p>
        <ul>
          <li>Provide and improve our products and services.</li>
          <li>Respond to customer inquiries and support requests.</li>
          <li>Send updates, newsletters, and promotional offers (with your consent).</li>
          <li>Analyze website traffic and improve website performance.</li>
          <li>Ensure compliance with legal obligations and prevent fraudulent activities.</li>
        </ul>

        <h2>3. How We Share Your Information</h2>
        <p>
          We respect your privacy and do not sell, rent, or trade your personal information. However, your data may be shared with:
        </p>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors (e.g., hosting, payment processors) under strict confidentiality agreements.</li>
          <li><strong>Legal Authorities:</strong> When required by law or in response to valid legal requests.</li>
          <li><strong>Business Transfers:</strong> In case of mergers, acquisitions, or asset sales, your information may be transferred.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>
          We implement robust security measures, including encryption, firewalls, and secure access controls, to protect your personal data from unauthorized access, loss, misuse, or alteration. However, no method of data transmission or storage is entirely secure, and we cannot guarantee absolute security.
        </p>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our website uses cookies and tracking technologies to:
        </p>
        <ul>
          <li>Personalize your browsing experience.</li>
          <li>Analyze website performance and traffic patterns.</li>
          <li>Store your preferences for future visits.</li>
        </ul>
        <p>
          You can control or disable cookies through your browser settings. However, disabling cookies may affect your user experience.
        </p>

        <h2>6. Your Rights and Choices</h2>
        <p>
          You have the following rights regarding your personal data:
        </p>
        <ul>
          <li><strong>Access and Update:</strong> You can access and update your personal information at any time.</li>
          <li><strong>Opt-Out:</strong> You can opt out of receiving marketing emails and communications.</li>
          <li><strong>Data Deletion:</strong> You can request the deletion of your personal data.</li>
          <li><strong>Data Portability:</strong> You can request a copy of your data in a structured format.</li>
        </ul>

        <h2>7. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We recommend reading their privacy policies before sharing any personal information.
        </p>

        <h2>8. Data Retention</h2>
        <p>
          We retain your personal data only for as long as necessary to fulfill the purposes outlined in this policy or as required by law.
        </p>

        <h2>9. Children's Privacy</h2>
        <p>
          Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us immediately.
        </p>

        <h2>10. International Data Transfers</h2>
        <p>
          Your information may be transferred and processed outside your country of residence. We ensure appropriate safeguards are in place to protect your data during international transfers.
        </p>

        <h2>11. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically. Any changes will be reflected on this page with an updated revision date. Significant changes will be communicated via email or website notifications.
        </p>

        <h2>12. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us:
        </p>
        <p>
          📧 <strong>Email:</strong> <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a><br />
          📞 <strong>Phone:</strong> <a href="tel:+917258803502">+91 7258803502</a><br />
        </p>
        <p>
          📍 <strong>Encoders Software Pvt. Ltd.</strong><br />
          Oak Forest, Flat No. 8F,<br />
          Pundag Road, Ranchi,<br />
          Jharkhand, 834002, India.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
