// src/components/Blogs.jsx

import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Blogs.css";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({
    title: "",
    content: "",
    author: localStorage.getItem("email") || "Guest",
    categoryId: "",
    image: "",
    imagePreview: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  // Fetch Blogs and Categories on Load
  useEffect(() => {
    loadBlogs();
    loadCategories();
  }, []);

  const loadBlogs = async () => {
    try {
      const response = await axiosInstance.get("/api/blogs");
      setBlogs(response.data.content || []);
    } catch (error) {
      toast.error("Failed to fetch blogs");
    }
  };

  const loadCategories = async () => {
    try {
      const response = await axiosInstance.get("/api/categories");
      setCategories(response.data || []);
    } catch (error) {
      toast.error("Failed to fetch categories");
    }
  };

  // Handle Create or Update Blog
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = { ...form };

      if (form.image) {
        payload.image = form.image;
      }

      if (selectedBlog) {
        await axiosInstance.put(`/api/blogs/${selectedBlog.id}`, payload);
        toast.success("Blog updated successfully!");
      } else {
        await axiosInstance.post("/api/blogs", payload);
        toast.success("Blog created successfully!");
      }

      setForm({
        title: "",
        content: "",
        author: localStorage.getItem("email") || "Guest",
        categoryId: "",
        image: "",
        imagePreview: null,
      });
      setSelectedBlog(null);
      setIsFormVisible(false);
      loadBlogs();
    } catch (error) {
      toast.error("Failed to save blog. Please check your inputs.");
    }
  };

  // Handle Delete Blog
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      try {
        await axiosInstance.delete(`/api/blogs/${id}`);
        toast.success("Blog deleted successfully!");
        loadBlogs();
      } catch (error) {
        toast.error("Failed to delete blog. Please try again later.");
      }
    }
  };

  // Handle Edit Blog
  const handleEdit = (blog) => {
    setSelectedBlog(blog);
    setForm({
      title: blog.title,
      content: blog.content,
      author: blog.author || localStorage.getItem("email") || "Guest",
      categoryId: blog.category?.id || "", // Ensure categoryId is set correctly
      image: "",
      imagePreview: blog.image ? `data:image/jpeg;base64,${blog.image}` : null,
    });
    setIsFormVisible(true);
  };

  // Handle Image Upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setForm({
          ...form,
          image: event.target.result.split(",")[1],
          imagePreview: URL.createObjectURL(file),
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Search Blogs
  const handleSearch = () => {
    const filtered = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      blog.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setBlogs(filtered);
  };

  return (
    <div className="blogs-container">
      <ToastContainer />

      {/* Header with Search and Add Buttons */}
      <div className="header">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search blogs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={handleSearch} className="search-btn">
            <FaSearch /> Search
          </button>
        </div>
        <button className="add-btn" onClick={() => setIsFormVisible(true)}>
          <FaPlus /> Add Blog
        </button>
      </div>

      {/* Form Modal */}
      {isFormVisible && (
        <div className="form-modal-overlay">
          <div className="form-modal">
            <form onSubmit={handleSubmit}>
              <label>Title</label>
              <input
                type="text"
                value={form.title}
                onChange={(e) => setForm({ ...form, title: e.target.value })}
                required
              />

              <label>Content</label>
              <textarea
                value={form.content}
                onChange={(e) => setForm({ ...form, content: e.target.value })}
                required
              />

              <label>Author</label>
              <input type="text" value={form.author} readOnly disabled />

              <label>Category</label>
<select
  value={form.categoryId}
  onChange={(e) => setForm({ ...form, categoryId: e.target.value })}
  required
>
  <option value="">Select Category</option>
  {categories.map((category) => (
    <option
      key={category.id}
      value={category.id}
    >
      {category.name}
    </option>
  ))}
</select>


              <label>Image</label>
              <input type="file" accept="image/*" onChange={handleImageChange} />
              {form.imagePreview && (
                <img src={form.imagePreview} alt="Preview" className="preview-image" />
              )}

              <div className="form-buttons">
                <button type="submit" className="submit-btn">Save</button>
                <button type="button" className="cancel-btn" onClick={() => setIsFormVisible(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Blog Cards */}
      <div className="blog-cards">
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-card">
            <img src={`data:image/jpeg;base64,${blog.image}`} alt="Blog" />
            <h3>{blog.title}</h3>
            <p>{blog.content}</p>
            <div className="card-actions">
              <button onClick={() => handleEdit(blog)}><FaEdit /> Edit</button>
              <button onClick={() => handleDelete(blog.id)}><FaTrash /> Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
