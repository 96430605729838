import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // Import Axios instance
import { FaEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageTestimonials.css"; // Include the provided CSS

const ManageTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({ id: null, message: "", client: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const fetchTestimonials = async () => {
    try {
      const response = await axiosInstance.get("/api/testimonials/search", {
        params: {
          query: searchTerm.trim(),
          page: currentPage - 1,
          size: pageSize === "all" ? undefined : pageSize,
        },
      });

      setTestimonials(response.data.content || response.data);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching testimonials:", error.response?.data || error.message);
      toast.error("Failed to load testimonials.");
    }

    

  };



  const handleCancel = () => {
    setFormData({ id: null, message: "", client: "" });
    setIsEditing(false);
    setShowForm(false);
  };

  useEffect(() => {
    fetchTestimonials();
  }, [currentPage, pageSize]);

  const handleSearchClick = () => {
    fetchTestimonials();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddEditTestimonial = async () => {
    try {
      if (!formData.message || !formData.client) {
        toast.error("Message and client are required.");
        return;
      }

      if (isEditing) {
        await axiosInstance.put(`/api/testimonials/${formData.id}`, formData);
        toast.success("Testimonial updated successfully!");
      } else {
        await axiosInstance.post("/api/testimonials", formData);
        toast.success("Testimonial added successfully!");
      }

      setFormData({ id: null, message: "", client: "" });
      setIsEditing(false);
      setShowForm(false);
      fetchTestimonials();
    } catch (error) {
      console.error("Error saving testimonial:", error.response?.data || error.message);
      toast.error("Failed to save testimonial.");
    }
  };

  const handleDeleteTestimonial = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this testimonial?")) {
        await axiosInstance.delete(`/api/testimonials/${id}`);
        toast.success("Testimonial deleted successfully!");
        fetchTestimonials();
      }
    } catch (error) {
      console.error("Error deleting testimonial:", error.response?.data || error.message);
      toast.error("Failed to delete testimonial.");
    }
  };

  const handleAddClick = () => {
    setFormData({ id: null, message: "", client: "" });
    setIsEditing(false);
    setShowForm(true);
  };

  return (
    <div className="manage-news-container">
      <ToastContainer />
      <h1>Manage Testimonials</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search testimonials..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick} className="search-button">
            <FaSearch />
          </button>
        </div>
        <select value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value="all">All</option>
        </select>
        <button onClick={handleAddClick} className="add-button">
          <FaPlus /> Add Testimonial
        </button>

       
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="client"
            placeholder="Client"
            value={formData.client}
            onChange={handleInputChange}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          <button onClick={handleAddEditTestimonial} className="form-submit-button">
            {isEditing ? "Update Testimonial" : "Add Testimonial"}
          </button>

           <button
        onClick={handleCancel}
        className="form-cancel-button"
        style={{ marginLeft: "10px" }}
      >
        Cancel
      </button>
        </div>
      )}

      {/* Testimonials Table */}
      <table>
        <thead>
          <tr>
            <th>Client</th>
            <th>Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonials.map((testimonial) => (
            <tr key={testimonial.id}>
              <td>{testimonial.client}</td>
              <td>{testimonial.message}</td>
              <td>
                <button
                  onClick={() => {
                    setFormData(testimonial);
                    setIsEditing(true);
                    setShowForm(true);
                  }}
                  className="edit"
                >
                  <FaEdit /> Edit
                </button>
                <button
                  onClick={() => handleDeleteTestimonial(testimonial.id)}
                  className="delete"
                >
                  <FaTrash /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {pageSize !== "all" && (
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageTestimonials;
