import React from 'react';
import './ShippingDelivery.css';
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";

const ShippingDelivery = () => {
  return (
    <div>
     
       <Navbar></Navbar>
    <div className="shipping-container">
      <h1>Shipping & Delivery Policy</h1>
      <p>
        At <strong>Encoders Software Pvt. Ltd.</strong>, we are committed to delivering your order accurately, in good condition, and always on time.
      </p>

      <h2>1. Shipping Policy</h2>
      <ul>
        <li>We offer standard and express shipping options across India.</li>
        <li>Orders are processed within <strong>1-2 business days</strong>.</li>
        <li>Delivery time may vary depending on the destination and shipping method chosen.</li>
        <li>Shipping charges (if applicable) will be displayed at checkout.</li>
      </ul>

      <h2>2. Delivery Timeline</h2>
      <ul>
        <li>Standard delivery: <strong>5-7 business days</strong>.</li>
        <li>Express delivery: <strong>2-3 business days</strong>.</li>
        <li>Remote locations may require additional time for delivery.</li>
      </ul>

      <h2>3. Order Tracking</h2>
      <p>
        Once your order is shipped, you will receive a tracking ID via email/SMS. You can track your order using the provided link.
      </p>

      <h2>4. Delivery Address</h2>
      <ul>
        <li>Please ensure the delivery address is accurate and complete.</li>
        <li>We are not responsible for orders delivered to incorrect addresses provided by the customer.</li>
      </ul>

      <h2>5. Delivery Charges</h2>
      <ul>
        <li>Delivery charges are calculated based on the weight, dimensions, and destination of the shipment.</li>
        <li>Shipping charges (if any) will be clearly displayed at the time of checkout.</li>
      </ul>

      <h2>6. Delayed or Failed Delivery</h2>
      <p>
        In case of any delays or failed deliveries, please contact our support team immediately. We will investigate the issue and provide an update as soon as possible.
      </p>

      <h2>7. International Shipping</h2>
      <p>
        At present, we do not offer international shipping. We deliver only across India. Please contact us if you have any special requests.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about your shipment, please contact our support team:
      </p>
      <p>
        📧 <strong>Email:</strong> <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a><br />
        📞 <strong>Phone:</strong> <a href="tel:+917258803502">+91 7258803502</a><br />
      </p>
      <p>
        📍 <strong>Encoders Software Pvt. Ltd.</strong><br />
        Oak Forest, Flat No. 8F,<br />
        Pundag Road, Ranchi,<br />
        Jharkhand, 834002, India.
      </p>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default ShippingDelivery;
