

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import React, { useState } from "react";
import JobList from "./JobList";
import SearchBar from "./SearchBar";
import FilterPanel from "./FilterPanel";
import "./Careers.css";




const Careers = () => {
  const [searchQuery, setSearchQuery] = useState(""); // Initialize searchQuery as an empty string
  const [filters, setFilters] = useState({
    remote: false, // Initialize filters with default values
    fullTime: false,
  });

  const handleViewOpenings = () => {
    const openingsSection = document.getElementById("openings");
    console.error("testing!");
    if (openingsSection) {
      openingsSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Openings section not found!");
    }
  };


  return (
    <div>
       
       <Navbar></Navbar>
    <div className="Careers-us">

      {/* Banner Section */}
      <div className="banner">
        <img
          src="/images/big-images/1.jpg"
          alt="Careers Banner"
          className="banner-image"
        />
        <div className="banner-overlay">
          <h1>Join Us and Build the Future of Technology</h1>
          <p>At Encoders Software, we believe in the power of innovation, collaboration, and passion. As a growing leader in IT services, we provide a dynamic environment where ideas flourish, careers thrive, and every contribution makes a meaningful impact. Whether you’re a seasoned professional or just starting your journey, we offer exciting opportunities to shape the future of technology.</p>
          <button className="view-openings-btn" onClick={handleViewOpenings}>
          View Openings
        </button>
        </div>
      </div>
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
     <div id="openings">
      <div className="content-row">
        <FilterPanel filters={filters} setFilters={setFilters} />
        <JobList searchQuery={searchQuery} filters={filters} />
      </div>
      </div>

      <Footer />
    </div>
    </div>
  );
};

export default Careers;
