import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import config from "../../include/config";
import "./ResetPassword.css";
import encrypt from "../../include/encrypt"

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Check if the token exists on component mount
  useEffect(() => {
    if (!token) {
      toast.error("Invalid or missing token.");
      navigate("/LoginSignup");
    }
  }, [token, navigate]);

  // Password validation utility function
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async () => {
    setErrorMessage("");

    if (!password || !confirmPassword) {
      setErrorMessage("Both fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character."
      );
      return;
    }

    setIsSubmitting(true);

    try {
      const encryptedPayload = encrypt({ password, confirmPassword });
      const response = await fetch(
        `${config.BASE_URL}/api/auth/reset-password?token=${encodeURIComponent(token)}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
         
          body: JSON.stringify({ data: encryptedPayload }),
        }
      );

      if (response.ok) {
        toast.success("Password reset successfully! Redirecting...");
        setTimeout(() => {
          navigate("/LoginSignup");
        }, 2000);
      } else {
        const error = await response.json();
        setErrorMessage(error.error || "Unable to reset password.");
        toast.error(error.error || "Unable to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="center-container">
      <div className="form-container">
        <h2>Reset Password</h2>
        <div className="input-group">
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {errorMessage && <p className="field-error">{errorMessage}</p>}
        <button
          className="submit-btn"
          onClick={handleResetPassword}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Resetting..." : "Reset Password"}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ResetPassword;
