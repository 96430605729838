import React, { useEffect } from 'react';
import './Dashboard.css';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Dashboard = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const dashboardData = [
    { id: 1, title: 'Happy Clients', count: 20, icon: '😊' },
    { id: 2, title: 'Services Offered', count: 20, icon: '🛠️' },
    { id: 3, title: 'Total Projects', count: 30, icon: '📊' },
    { id: 4, title: 'Internships Completed', count: 350, icon: '🎓' },
    { id: 5, title: 'School & College Projects', count: 200, icon: '🏫' },
  ];

  return (
    <section className="dashboard">
      <div className="container">
        <h2 className="dashboard-title">Our Achievements</h2>
        <div className="dashboard-cards">
          {dashboardData.map((item) => (
            <div
              key={item.id}
              className="dashboard-card"
              data-aos="fade-up"
              data-aos-delay={item.id * 100}
            >
              <div className="icon">{item.icon}</div>
              <h3>
                <CountUp start={0} end={item.count} duration={3} separator="," />+
              </h3>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;