import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./ApplyForm.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import config from "../../include/config";

const ApplyForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { job } = location.state || {}; // Extract job data

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    skillSet: "",
    resume: null,
    captchaInput: "", // Captcha entered by user
  });

  const [captcha, setCaptcha] = useState(""); // Captcha from server
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch captcha on component mount
  useEffect(() => {
  
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      console.log("capcha calling in progress")
      const response = await axios.get(`${config.BASE_URL}/api/captcha/generate`);
      setCaptcha(response.data);
    } catch (error) {
      console.error("Error fetching captcha:", error);
      alert("Failed to load captcha. Please try again.");
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      resume: e.target.files[0],
    }));
  };

  // Validation logic
  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";
    if (!formData.mobile.trim()) newErrors.mobile = "Mobile number is required.";
    if (!/^\d{10}$/.test(formData.mobile.trim()))
      newErrors.mobile = "Enter a valid 10-digit mobile number.";
    if (!formData.zipCode.trim()) newErrors.zipCode = "Pin code is required.";
    if (!/^\d+$/.test(formData.zipCode.trim()))
      newErrors.zipCode = "Pin code must be numeric.";
    if (!formData.resume) newErrors.resume = "Resume upload is required.";
    if (!formData.captchaInput.trim()) newErrors.captchaInput = "Captcha is required.";
    if (formData.captchaInput.trim().toLowerCase() !== captcha.trim().toLowerCase()) 
      newErrors.captchaInput = "Captcha does not match.";
    
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "captchaInput") formDataToSend.append(key, formData[key]);
    });
    formDataToSend.append("email", localStorage.getItem("email") || "");
    formDataToSend.append("jobId", job?.id || 0);
    formDataToSend.append("captcha", formData.captchaInput);

    axios
      .post(`${config.BASE_URL}/api/apply`, formDataToSend)
      .then(() => {
        alert("Thank you! Your application has been submitted.");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error submitting the application:", error.response || error.message);
        alert("Failed to submit. Please try again.");
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div>
      <Navbar />
      <div className="apply-form-wrapper">
        <div className="banner">
          <img
            src="/images/big-images/job_apply.webp"
            alt="Careers Banner"
            className="banner-image"
          />
        </div>
        <h2>Apply for {job?.title || "Job"}</h2>
        <form className="apply-form-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && <p className="error-text">{errors.firstName}</p>}
          </div>
          <div className="form-group">
            <label>Last Name *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && <p className="error-text">{errors.lastName}</p>}
          </div>
          <div className="form-group">
            <label>Mobile *</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && <p className="error-text">{errors.mobile}</p>}
          </div>
          <div className="form-group">
            <label>Street</label>
            <input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Zip Code *</label>
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
            />
            {errors.zipCode && <p className="error-text">{errors.zipCode}</p>}
          </div>
          <div className="form-group">
            <label>Country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Skill Set</label>
            <textarea
              name="skillSet"
              value={formData.skillSet}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Resume *</label>
            <input type="file" onChange={handleFileChange} />
            {errors.resume && <p className="error-text">{errors.resume}</p>}
          </div>
          <div className="form-group">
            <label>Captcha *</label>
            <div className="captcha-box">
              <span className="captcha-text">{captcha}</span>
              <button
                type="button"
                className="refresh-captcha"
                onClick={fetchCaptcha}
              >
                ↻
              </button>
            </div>
            <input
              type="text"
              name="captchaInput"
              value={formData.captchaInput}
              onChange={handleChange}
              placeholder="Enter the captcha"
            />
            {errors.captchaInput && (
              <p className="error-text">{errors.captchaInput}</p>
            )}
          </div>
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyForm;
