import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

/**
 * Generate CSV File from Application Data
 * @param {Array} data - Array of application objects
 * @param {Array} selectedColumns - Array of selected column keys
 */
export const downloadCSV = (data, selectedColumns) => {
  try {
    if (!data || data.length === 0) {
      console.warn('No data available for CSV export');
      return;
    }

    const csvContent = [
      selectedColumns,
      ...data.map(app => selectedColumns.map(col => app[col] || ''))
    ]
      .map(row => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `applications_report_${new Date().toISOString()}.csv`;
    link.click();
    console.info('CSV file exported successfully');
  } catch (error) {
    console.error('Error exporting CSV:', error);
  }
};

/**
 * Generate PDF File from Application Data
 * @param {Array} data - Array of application objects
 * @param {Array} selectedColumns - Array of selected column keys
 */
export const downloadPDF = (data, selectedColumns) => {
  try {
    if (!data || data.length === 0) {
      console.warn('No data available for PDF export');
      return;
    }

    const doc = new jsPDF();
    doc.text('Applications Report', 14, 16);

    const tableData = data.map(app =>
      selectedColumns.map(col => app[col] || '')
    );

    autoTable(doc, {
      head: [selectedColumns],
      body: tableData,
      theme: 'grid',
      headStyles: { fillColor: [22, 160, 133] }, // Teal Header
      alternateRowStyles: { fillColor: [240, 240, 240] }, // Alternate rows light grey
      margin: { top: 20 },
    });

    doc.save(`applications_report_${new Date().toISOString()}.pdf`);
    console.info('PDF file exported successfully');
  } catch (error) {
    console.error('Error exporting PDF:', error);
  }
};

/**
 * Generate Excel File from Application Data
 * @param {Array} data - Array of application objects
 * @param {Array} selectedColumns - Array of selected column keys
 */
export const downloadExcel = (data, selectedColumns) => {
  try {
    if (!data || data.length === 0) {
      console.warn('No data available for Excel export');
      return;
    }

    let excelContent = `<table><thead><tr>`;
    selectedColumns.forEach(col => {
      excelContent += `<th>${col}</th>`;
    });
    excelContent += `</tr></thead><tbody>`;

    data.forEach(app => {
      excelContent += `<tr>`;
      selectedColumns.forEach(col => {
        excelContent += `<td>${app[col] || ''}</td>`;
      });
      excelContent += `</tr>`;
    });

    excelContent += `</tbody></table>`;

    const blob = new Blob([excelContent], {
      type: 'application/vnd.ms-excel;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `applications_report_${new Date().toISOString()}.xls`;
    link.click();
    console.info('Excel file exported successfully');
  } catch (error) {
    console.error('Error exporting Excel:', error);
  }
};

/**
 * Generate JSON File from Application Data
 * @param {Array} data - Array of application objects
 */
export const downloadJSON = (data) => {
  try {
    if (!data || data.length === 0) {
      console.warn('No data available for JSON export');
      return;
    }

    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `applications_report_${new Date().toISOString()}.json`;
    link.click();
    console.info('JSON file exported successfully');
  } catch (error) {
    console.error('Error exporting JSON:', error);
  }
};
