import React from 'react';
import './ CancellationRefund.css';
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";

const CancellationRefund = () => {
  return (
    <div>
    <Navbar></Navbar>
    <div className="policy-container">
      <h1>Cancellation & Refund Policy</h1>
      <p>
        Welcome to <strong>Encoders Software Project</strong> by <strong>Encoders Software Pvt. Ltd.</strong>. 
        We are dedicated to providing high-quality products and services. Please carefully read our cancellation and refund policy before making a purchase.
      </p>

      <h2>1. Cancellation Policy</h2>
      <ul>
        <li>Orders can be canceled within <strong>24 hours</strong> of purchase without any penalty.</li>
        <li>Once an order is shipped or a digital product is delivered, cancellation will not be possible.</li>
        <li>To request a cancellation, please email us at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a>.</li>
        <li>Refunds for canceled orders will be processed within <strong>7-10 business days</strong>.</li>
      </ul>

      <h2>2. Refund Policy</h2>
      <ul>
        <li>Refunds are processed only for eligible requests as per our policies.</li>
        <li>Refunds are not applicable on digital products or services that have been accessed or used.</li>
        <li>Shipping charges (if applicable) are non-refundable.</li>
        <li>If you haven’t received your refund within the stated timeline, please contact us at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a>.</li>
      </ul>

      <h2>3. Eligibility for Refund</h2>
      <ul>
        <li>The product must be unused and in its original condition (if physical).</li>
        <li>Refund requests must be raised within <strong>7 days</strong> of purchase.</li>
        <li>Digital product refunds will only be considered if there are technical issues preventing access.</li>
      </ul>

      <h2>4. Process for Refund</h2>
      <ol>
        <li>Email your refund request to <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a> with your order details.</li>
        <li>Our team will review the request and respond within <strong>3-5 business days</strong>.</li>
        <li>Approved refunds will be processed within <strong>7-10 business days</strong>.</li>
      </ol>

      <h2>5. Non-Refundable Items/Services</h2>
      <ul>
        <li>Downloaded digital products.</li>
        <li>Subscription-based services after usage.</li>
        <li>Customized software solutions.</li>
      </ul>

      <h2>6. Late or Missing Refunds</h2>
      <p>
        If you haven’t received a refund yet:
      </p>
      <ul>
        <li>Check your bank account.</li>
        <li>Contact your credit card company; it may take some time before your refund is officially posted.</li>
        <li>If you've done all this and still haven’t received your refund, please contact us at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a>.</li>
      </ul>

      <h2>7. Contact Us</h2>
      <p>
        For any queries or assistance regarding our Cancellation & Refund Policy, please contact:
      </p>
      <p>
        📧 Email: <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a><br />
        📞 Phone: <a href="tel:+917258803502">+91 7258803502</a>
      </p>
      <p>
        📍 <strong>Encoders Software Pvt. Ltd.</strong><br />
        Oak Forest, Flat No. 8F,<br />
        Pundag Road, Ranchi,<br />
        Jharkhand, 834002, India.
      </p>
    </div>
    <Footer></Footer>
    </div>
    
  );
};

export default CancellationRefund;
