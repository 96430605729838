import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import config from "../../include/config";

const ActivateAccount = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      setError('Activation token is missing.');
      setLoading(false);
      return;
    }

    const activateAccount = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}/api/auth/activate?token=${token}`
        );
        setMessage(response.data.message || 'Account activated successfully!');
      } catch (err) {
        setError(
          err.response?.data?.error || 'Failed to activate account. Try again.'
        );
      } finally {
        setLoading(false);
      }
    };

    activateAccount();
  }, [searchParams]);

  return (
    <div style={styles.container}>
      {loading ? (
        <p style={styles.message}>Activating your account...</p>
      ) : error ? (
        <p style={styles.error}>{error}</p>
      ) : (
        <p style={styles.success}>{message}</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  message: {
    fontSize: '18px',
    color: '#444',
  },
  success: {
    fontSize: '20px',
    color: 'green',
  },
  error: {
    fontSize: '20px',
    color: 'red',
  },
};

export default ActivateAccount;
