import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // Import Axios instance
import { FaEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageFAQs.css";

const ManageFAQs = () => {
  const [faqs, setFAQs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({ id: null, question: "", answer: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const fetchFAQs = async () => {
    try {
      const response = await axiosInstance.get("/api/faqs", {
        params: {
          keyword: searchTerm.trim(),
          page: currentPage - 1,
          size: pageSize === "all" ? undefined : pageSize,
        },
      });

      setFAQs(response.data.content || response.data);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching FAQs:", error.response?.data || error.message);
      toast.error("Failed to load FAQs.");
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, [currentPage, pageSize]);

  const handleSearchClick = () => {
    fetchFAQs();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddEditFAQ = async () => {
    try {
      if (!formData.question || !formData.answer) {
        toast.error("Question and answer are required.");
        return;
      }

      if (isEditing) {
        await axiosInstance.put(`/api/faqs/${formData.id}`, formData);
        toast.success("FAQ updated successfully!");
      } else {
        await axiosInstance.post("/api/faqs", formData);
        toast.success("FAQ added successfully!");
      }

      setFormData({ id: null, question: "", answer: "" });
      setIsEditing(false);
      setShowForm(false);
      fetchFAQs();
    } catch (error) {
      console.error("Error saving FAQ:", error.response?.data || error.message);
      toast.error("Failed to save FAQ.");
    }
  };

  const handleDeleteFAQ = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this FAQ?")) {
        await axiosInstance.delete(`/api/faqs/${id}`);
        toast.success("FAQ deleted successfully!");
        fetchFAQs();
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error.response?.data || error.message);
      toast.error("Failed to delete FAQ.");
    }
  };

  const handleAddClick = () => {
    setFormData({ id: null, question: "", answer: "" });
    setIsEditing(false);
    setShowForm(true);
  };

  return (
    <div className="manage-news-container">
      <ToastContainer />
      <h1>Manage FAQs</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick} className="search-button">
            <FaSearch />
          </button>
        </div>
        <select value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value="all">All</option>
        </select>
        <button onClick={handleAddClick} className="add-button">
          <FaPlus /> Add FAQ
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="question"
            placeholder="Question"
            value={formData.question}
            onChange={handleInputChange}
          />
          <textarea
            name="answer"
            placeholder="Answer"
            value={formData.answer}
            onChange={handleInputChange}
          ></textarea>
          <button onClick={handleAddEditFAQ} className="form-submit-button">
            {isEditing ? "Update FAQ" : "Add FAQ"}
          </button>

          <button
        onClick={() => {
          setFormData({ id: null, question: "", answer: "" });
          setIsEditing(false);
          setShowForm(false);
        }}
        className="form-cancel-button"
      >
        Cancel
      </button>
        </div>
      )}

      {/* FAQs Table */}
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq) => (
            <tr key={faq.id}>
              <td>{faq.question}</td>
              <td>{faq.answer}</td>
              <td>
                <button
                  onClick={() => {
                    setFormData(faq);
                    setIsEditing(true);
                    setShowForm(true);
                  }}
                  className="edit"
                >
                  <FaEdit /> Edit
                </button>
                <button
                  onClick={() => handleDeleteFAQ(faq.id)}
                  className="delete"
                >
                  <FaTrash /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {pageSize !== "all" && (
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageFAQs;
