import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch, FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageServices.css";

const ManageServices = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({ id: null, title: "", description: "", imageBase64: null });
  const [previewImage, setPreviewImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level

  // Fetch services from API
  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get("/api/services", {
        params: {
          page: currentPage - 1,
          size: pageSize,
          keyword: searchTerm.trim(),
        },
      });

      const { services: fetchedServices, totalPages } = response.data;
      setServices(Array.isArray(fetchedServices) ? fetchedServices : []);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error("Error fetching services:", error.response?.data || error.message);
      toast.error("Failed to load services.");
    }
  };

  useEffect(() => {
    fetchServices();
  }, [currentPage, pageSize]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;

        if (base64String.startsWith("data:image")) {
          setFormData({ ...formData, imageBase64: base64String }); // Store the full string in formData
          setPreviewImage(base64String); // For preview
        } else {
          toast.error("Invalid image format");
          console.error("Invalid Base64 string");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Add or edit service
  const handleAddEditService = async () => {
    if (!formData.title.trim() || !formData.description.trim()) {
      toast.error("Title and description are required.");
      return;
    }

    try {
      if (isEditing) {
        await axiosInstance.put(`/api/services/${formData.id}`, formData);
        toast.success("Service updated successfully!");
      } else {
        await axiosInstance.post("/api/services", formData);
        toast.success("Service added successfully!");
      }
      resetForm();
      fetchServices();
    } catch (error) {
      console.error("Error saving service:", error.response?.data || error.message);
      toast.error(error.response?.data?.message || "Failed to save service.");
    }
  };

  // Set Form Data for Editing
  const handleEditClick = (service) => {
    setFormData({
      id: service.id,
      title: service.title,
      description: service.description,
      imageBase64: service.image ? `data:image/jpeg;base64,${service.image}` : null,
    });
    setPreviewImage(service.image ? `data:image/jpeg;base64,${service.image}` : null);
    setIsEditing(true);
    setShowForm(true);
  };

  // Delete a service
  const handleDeleteService = async (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        await axiosInstance.delete(`/api/services/${id}`);
        toast.success("Service deleted successfully!");
        fetchServices();
      } catch (error) {
        console.error("Error deleting service:", error.response?.data || error.message);
        toast.error("Failed to delete service.");
      }
    }
  };

  // Reset the form
  const resetForm = () => {
    setFormData({ id: null, title: "", description: "", imageBase64: null });
    setPreviewImage(null);
    setIsEditing(false);
    setShowForm(false);
    setZoomLevel(1); // Reset zoom level
  };

  // Search services
  const handleSearch = () => {
    setCurrentPage(1);
    fetchServices();
  };

  // Handle Zoom In
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); // Max zoom level: 3x
  };

  // Handle Zoom Out
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); // Min zoom level: 1x
  };

  return (
    <div className="manage-services-container">
      <ToastContainer />
      <h1>Manage Services</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search services..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch} className="search-button">
            <FaSearch />
          </button>
        </div>
        <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
        <button onClick={() => setShowForm(true)} className="add-button">
          <FaPlus /> Add Service
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleInputChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
          ></textarea>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          {previewImage && (
            <div className="image-preview-container">
              <img
                src={previewImage}
                alt="Preview"
                className="image-preview"
                style={{ transform: `scale(${zoomLevel})` }}
              />
              <div className="zoom-controls">
                <button onClick={handleZoomIn} className="zoom-button">
                  <FaSearchPlus />
                </button>
                <button onClick={handleZoomOut} className="zoom-button">
                  <FaSearchMinus />
                </button>
              </div>
            </div>
          )}
          <button onClick={handleAddEditService} className="form-submit-button">
            {isEditing ? "Update Service" : "Add Service"}
          </button>
          <button onClick={resetForm} className="cancel-button">
            Cancel
          </button>
        </div>
      )}

      {/* Services Table */}
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {services.length > 0 ? (
            services.map((service) => (
              <tr key={service.id}>
                <td>{service.title}</td>
                <td>{service.description}</td>
                <td>
                  {service.image && (
                    <img
                      src={`data:image/jpeg;base64,${service.image}`}
                      alt={service.title}
                      className="service-image"
                    />
                  )}
                </td>
                <td>
                  <button onClick={() => handleEditClick(service)} className="edit-button">
                    <FaEdit /> Edit
                  </button>
                  <button onClick={() => handleDeleteService(service.id)} className="delete-button">
                    <FaTrash /> Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No services available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ManageServices;
