import React, { useState, useEffect } from 'react';
import AdminControls from './AdminControls';
import ColumnSelector from './ColumnSelector';
import DataTable from './DataTable';
import GraphDisplay from './GraphDisplay';
import { fetchAllApplications } from './apiService';
import { downloadCSV, downloadPDF, downloadExcel, downloadJSON } from './exportService';
import './AdminDashboard.css';

/**
 * AdminDashboard Component
 * Manages application data, filters, column selection, and visualization.
 */
const AdminDashboard = () => {
  const [applications, setApplications] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([
    'id',
    'firstName',
    'lastName',
    'mobile',
    'city',
    'state',
    'email',
    'status',
    'updatedAt',
  ]);
  const [selectedGraphs, setSelectedGraphs] = useState(['Bar']);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [updateAtFilter, setUpdateAtFilter] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const allColumns = [
    'id',
    'firstName',
    'lastName',
    'mobile',
    'street',
    'city',
    'state',
    'zipCode',
    'country',
    'skillSet',
    'email',
    'jobId',
    'status',
    'reason',
    'captcha',
    'createdAt',
    'updatedAt',
  ];
  const graphTypes = ['Bar', 'Line', 'Pie', 'Doughnut', 'Radar'];

  /**
   * Fetch all application data
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchAllApplications();
      setApplications(data);
      prepareGraphData(data);
    } catch (err) {
      setError('Failed to fetch applications. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Filtered data based on status and updated date
   */
  const filteredApplications = applications.filter((app) => {
    const statusMatch = statusFilter === 'ALL' || app.status === statusFilter;
    const updateMatch =
      updateAtFilter === 'ALL' ||
      new Date(app.updatedAt).toISOString().split('T')[0] === updateAtFilter;
    return statusMatch && updateMatch;
  });

  /**
   * Prepare data for graph visualization
   */
  const prepareGraphData = (data) => {
    const statusCounts = data.reduce((acc, app) => {
      const status = app.status || 'No Status';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    setGraphData({
      labels: Object.keys(statusCounts),
      datasets: [
        {
          label: 'Application Status',
          data: Object.values(statusCounts),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          borderColor: '#ddd',
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <div className="admin-dashboard-container">
      <h1>Admin Dashboard</h1>

      {/* Admin Controls Section */}
      <AdminControls
        fetchData={fetchData}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        updateAtFilter={updateAtFilter}
        setUpdateAtFilter={setUpdateAtFilter}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        allColumns={allColumns}
        downloadCSV={() => downloadCSV(filteredApplications, selectedColumns)}
        downloadPDF={() => downloadPDF(filteredApplications, selectedColumns)}
        downloadExcel={() => downloadExcel(filteredApplications, selectedColumns)}
        downloadJSON={() => downloadJSON(filteredApplications)}
        graphTypes={graphTypes}
        selectedGraphs={selectedGraphs}
        setSelectedGraphs={setSelectedGraphs}
      />

      {/* Column Selector */}
      <ColumnSelector
        allColumns={allColumns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />

      {/* Data Table */}
      <h2>Application Data</h2>
      {loading ? (
        <div className="loading">Loading data...</div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : (
        <DataTable data={filteredApplications} selectedColumns={selectedColumns} />
      )}

      {/* Graph Display */}
      <h2>Application Data Visualization</h2>
      {graphData ? (
        <GraphDisplay graphData={graphData} selectedGraphs={selectedGraphs} />
      ) : (
        <div className="graph-placeholder">No graph data available</div>
      )}
    </div>
  );
};

export default AdminDashboard;
