import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { Bar } from "react-chartjs-2";
import ProgressTimeline from "./ProgressTimeline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./ViewApplications.css";
import { FaCheck, FaTimes, FaDownload, FaEye } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ViewApplications = () => {
  const [email, setEmail] = useState("");
  const [applications, setApplications] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [loading, setLoading] = useState(false);
   const [resumeView, setResumeView] = useState(null);

  // Handle session and token
  useEffect(() => {
    const sessionEmail = localStorage.getItem("email");
    const sessionToken = localStorage.getItem("accessToken");

    if (sessionToken) {
      setIsLoggedIn(true);
      if (sessionEmail) {
        setEmail(sessionEmail);
        setShowSearchBox(false); // Hide search box if email is preloaded
        fetchApplications(sessionEmail);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);


  const determineCurrentStep = (application) => {
    if (application.status === "Pending") return 0; // Step 0: Initiated
    if (
     ( application.status === "DECLINED" ||
      application.status === "ACCEPTED") && application.selectionStatus === 0)
    
      return 1; // Step 1: Resume Accepted/Rejected
    if (application.status === "ACCEPTED" && application.selectionStatus === 1) {
      if (application.offerLetterGenerated) return 3; // Step 3: Offer Letter Generated
      return 2; // Step 2: Selected for Post
    }
    return 0; // Default to Step 0: Initiated
  };
  


  const handleDownloadOfferLetter = async (application) => {
    try {
      // Make the GET request to download the offer letter
      const response = await axiosInstance.get("/api/applications/download-offer-letter", {
        params: {
          empNo: application.empNo,
          email: application.email,
        },
        responseType: 'arraybuffer', // Important: This ensures the response is treated as binary data
      });
  
      // Create a Blob from the binary data
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  
      // Create a link to trigger the file download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = 'offer-letter.pdf'; // You can change the name of the file here
      link.click(); // Trigger the download
  
      console.log('Offer letter downloaded successfully!');
    } catch (error) {
      console.error('Error downloading offer letter:', error);
    }
  };



  const handleViewResume = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/${id}/resume/view`, {
        responseType: "blob",
      });
      const fileURL = URL.createObjectURL(response.data);
      setResumeView(fileURL);
    } catch (error) {
      console.error("Error loading resume:", error.response?.data || error.message);
      toast.error("Failed to load resume.");
    }
  };

  // Fetch Applications
  const fetchApplications = async (inputEmail) => {
    const searchEmail = inputEmail || email;
    const token = localStorage.getItem("accessToken");

    if (!searchEmail || !/\S+@\S+\.\S+/.test(searchEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (!token) {
      setError("Unauthorized. Please log in again.");
      return;
    }

    setError("");
    setLoading(true);
    setApplications([]);
    setGraphData(null);

    try {
      const response = await axiosInstance.get('/api/view', {
        params: { email: searchEmail },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.length > 0) {
        setApplications(response.data);
        prepareGraphData(response.data);
        setShowSearchBox(false);
      } else {
        setError("No resumes have been replied to for this email.");
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setError("Session expired. Please log in again.");
        localStorage.removeItem("accessToken");
        setIsLoggedIn(false);
      } else if (err.response?.status === 404) {
        setError("No resumes have been replied to for this email.");
      } else if (err.code === "ECONNABORTED") {
        setError("The request took too long. Please try again later.");
      } else {
        setError("Failed to fetch applications. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

 

  // Prepare Graph Data
  const prepareGraphData = (data) => {
    const statusCounts = data.reduce((acc, app) => {
      acc[app.status] = (acc[app.status] || 0) + 1;
      return acc;
    }, {});

    setGraphData({
      labels: Object.keys(statusCounts),
      datasets: [
        {
          label: "Application Status",
          data: Object.values(statusCounts),
          backgroundColor: "rgba(75,192,192,0.6)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <div className="container">
      <h1>Application Dashboard</h1>

      {/* Authentication Check */}
      {!isLoggedIn ? (
        <div className="error">You must be logged in to view applications.</div>
      ) : (
        <>
          {/* Search Box */}
          {showSearchBox && (
            <div className="search-box">
              <h2>Find Your Applications</h2>
              <div className="search-input">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button onClick={() => fetchApplications()}>
                  {loading ? "Searching..." : "🔍 Search"}
                </button>
              </div>
            </div>
          )}

          {/* Loading State */}
          {loading && (
            <div className="loading">Loading applications, please wait...</div>
          )}

            {/* Resume Viewer */}
      {resumeView && (
        <div className="resume-viewer">
          <iframe src={resumeView} width="100%" height="500px" title="Resume Viewer"></iframe>
          <button className="close-button" onClick={() => setResumeView(null)}>
            Close Viewer
          </button>
        </div>
      )}

          {/* Error Message */}
          {error && <div className="error">{error}</div>}

          {/* Applications Table */}
          {applications.length > 0 && (
            <div className="table-container">
              <h2>Application Status</h2>
              <table>
                <thead>
                  <tr>
                    <th>Job ID</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Message</th>
                    <th>View Resume</th>
                    <th>Download Offer Letter</th>
                    <th>Applied Date</th>
                  </tr>
                </thead>
                <tbody>
  {applications.map((app) => (
    <React.Fragment key={app.id}>
      <tr>
        <td>{app.jobId}</td>
        <td>{app.email}</td>
        <td>{app.status}</td>
        <td>{app.reason || "N/A"}</td>
        <td>
          <button
            className="action-button view"
            onClick={() => handleViewResume(app.id)}
          >
            <FaEye /> View
          </button>
        </td>
        <td>
          {app.offerLetterGenerated  ? (
            <button
              className="action-button download"
              onClick={() => handleDownloadOfferLetter(app)}
            >
              <FaDownload /> Download Offer Letter
            </button>
          ) : (
            "In progress..."
          )}
        </td>
        <td>{new Date(app.createdAt).toLocaleString()}</td>
      </tr>
      {/* Progress Timeline Row */}
      <tr>
        <td colSpan="7">
          <ProgressTimeline currentStep={determineCurrentStep(app)} />
        </td>
      </tr>
    </React.Fragment>
  ))}
</tbody>

                
              </table>
            </div>
          )}

          {/* Status Graph */}
          {graphData && (
            <div className="chart-section">
              <h2>Status Graph</h2>
              <div className="chart-container">
                <Bar 
                  data={graphData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: { position: 'top' },
                      title: { display: true, text: 'Application Status Overview' },
                    },
                    scales: {
                      x: { title: { display: true, text: 'Status' } },
                      y: { title: { display: true, text: 'Count' }, beginAtZero: true },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewApplications;
