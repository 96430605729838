import React from 'react';
import PropTypes from 'prop-types';
import './DataTable.css';

/**
 * DataTable Component
 * @param {Object} props - Props passed from parent component
 * @param {Array} data - Array of application data objects
 * @param {Array} selectedColumns - List of selected columns to display
 */
const DataTable = ({ data, selectedColumns }) => {
  if (!data || data.length === 0) {
    return <div className="data-table-empty">No data available</div>;
  }

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {selectedColumns.map((col) => (
              <th key={col}>{col.replace(/([A-Z])/g, ' $1').trim()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {selectedColumns.map((col) => (
                <td key={`${rowIndex}-${col}`}>
                  {row[col] !== null && row[col] !== undefined ? row[col] : '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * PropTypes Validation
 */
DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
};

export default DataTable;
