
// About.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import Dashboard from "./Dashboard";
import HeroSection from "./HeroSection";
import AboutCompany from "./AboutCompany";
import ServicesSection from "./ServicesSection";
import ProjectDelivery from "./ProjectDelivery";
import TeamSection from "./TeamSection";
import BannerSection from "./BannerSection";
import config from "../../include/config";
import axios from "axios";
import "./About.css";

const About = () => {
  const navigate = useNavigate();
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState(null);

  const handleNavigate = () => {
    navigate("/contact");
  };

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/team-members/getAllTeamMembers`);
        setTeamMembers(response.data);
      } catch (err) {
        console.error("Error fetching team members:", err);
        setError("Failed to fetch team members. Please try again later.");
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div>
      <Navbar />      
      <HeroSection />
      <section className="company-overview">
  <h2>About Encoders Software</h2>
  <p>
    Encoders Software Private Limited is a leading IT solutions provider, founded in <strong>2019</strong> and headquartered in <strong>Ranchi, Jharkhand, India</strong>.
    Known for innovation, excellence, and customer-first solutions, we are committed to delivering cutting-edge technology services across industries.
  </p>
  <ul>
    <li><strong>Founded:</strong> 2019</li>
    <li><strong>CIN:</strong> U74999JH2019PTC012902</li>
  </ul>
  <p>
    With a focus on <strong>customer satisfaction</strong>, <strong>innovation</strong>, and <strong>integrity</strong>, Encoders Software continues to empower businesses to thrive in the digital era.
  </p>
</section>
<AboutCompany />
   
      <ServicesSection />
      <Dashboard />
      <ProjectDelivery />
      <TeamSection teamMembers={teamMembers} />
      <BannerSection handleNavigate={handleNavigate} />
      <Footer />
    </div>
  );
};

export default About;
