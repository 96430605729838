import axiosInstance from "./axiosInstance";

export const fetchAllApplications = async () => {
  try {
    const response = await axiosInstance.get('/api/view/all');
    return response.data;
  } catch (error) {
    throw error;
  }
};
