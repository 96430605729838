import React from "react";
import PropTypes from "prop-types";
import "./CategoryList.css";

const CategoryList = ({ categories, onCategoryClick, selectedCategory }) => {
  return (
    <div className="category-list">
      <h3 className="category-title">🗂️ Categories</h3>
      <ul>
        <li
          className={`category-item ${!selectedCategory ? "active" : ""}`}
          onClick={() => onCategoryClick("")}
        >
          All Categories
        </li>
        {categories.map((category) => (
          <li
            key={category.id}
            className={`category-item ${
              selectedCategory === category.id ? "active" : ""
            }`}
            onClick={() => onCategoryClick(category.id)}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CategoryList;
