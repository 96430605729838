import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // Import Axios instance
import { FaEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageNews.css";

const ManageNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({ id: null, title: "", description: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const fetchNews = async () => {
    try {
      const response = await axiosInstance.get("/api/latest-news", {
        params: {
          view: "pagewise",
          page: currentPage - 1,
          size: pageSize === "all" ? undefined : pageSize,
          keyword: searchTerm.trim(),
        },
      });

      setNewsList(response.data.content || response.data);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching news:", error.response?.data || error.message);
      toast.error("Failed to load news.");
    }
  };

  useEffect(() => {
    fetchNews();
  }, [currentPage, pageSize]);

  const handleSearchClick = () => {
    fetchNews();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddEditNews = async () => {
    try {
      if (!formData.title || !formData.description) {
        toast.error("Title and description are required.");
        return;
      }

      if (isEditing) {
        await axiosInstance.put(`/api/latest-news/${formData.id}`, formData);
        toast.success("News updated successfully!");
      } else {
        await axiosInstance.post("/api/latest-news", formData);
        toast.success("News added successfully!");
      }

      setFormData({ id: null, title: "", description: "" });
      setIsEditing(false);
      setShowForm(false);
      fetchNews();
    } catch (error) {
      console.error("Error saving news:", error.response?.data || error.message);
      toast.error("Failed to save news.");
    }
  };

  const handleDeleteNews = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this news?")) {
        await axiosInstance.delete(`/api/latest-news/${id}`);
        toast.success("News deleted successfully!");
        fetchNews();
      }
    } catch (error) {
      console.error("Error deleting news:", error.response?.data || error.message);
      toast.error("Failed to delete news.");
    }
  };

  const handleAddClick = () => {
    setFormData({ id: null, title: "", description: "" });
    setIsEditing(false);
    setShowForm(true);
  };

  return (
    <div className="manage-news-container">
      <ToastContainer />
      <h1>Manage News</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search news..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick} className="search-button">
            <FaSearch />
          </button>
        </div>
        <select value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value="all">All</option>
        </select>
        <button onClick={handleAddClick} className="add-button">
          <FaPlus /> Add News
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleInputChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
          ></textarea>
          <button onClick={handleAddEditNews} className="form-submit-button">
            {isEditing ? "Update News" : "Add News"}
          </button>

          <button
        onClick={() => {
          setShowForm(false); // Close the form
          setFormData({ id: null, title: "", description: "" }); // Reset form data
          setIsEditing(false); // Reset editing state
        }}
        className="cancel-button"
      >
        Cancel
      </button>
        </div>
      )}

      {/* News Table */}
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newsList.map((news) => (
            <tr key={news.id}>
              <td>{news.title}</td>
              <td>{news.description}</td>
              <td>
                <button
                  onClick={() => {
                    setFormData(news);
                    setIsEditing(true);
                    setShowForm(true);
                  }}
                  className="edit"
                >
                  <FaEdit /> Edit
                </button>
                <button onClick={() => handleDeleteNews(news.id)} className="delete">
                  <FaTrash /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {pageSize !== "all" && (
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageNews;
