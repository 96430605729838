import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./axiosInstance";
import "./InterViewStatusForm.css";

const InterViewStatusForm = ({ application, onSubmit, onCancel }) => {
  const [status, setStatus] = useState(""); // For dropdown selection
  const [selectionMessage, setSelectionMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
  e.preventDefault();

  if (!status) {
    toast.error("Please select a status (Selected or Rejected).");
    return;
  }

  if (!selectionMessage.trim()) {
    toast.error("Selection message is required.");
    return;
  }

  const payload = {
    status: parseInt(status, 10),
    selectionMessage,
  };

  console.log("Payload being sent:", payload); // Debug the payload

  try {
    await axiosInstance.put(`/api/applications/update-selection/${application.id}`, payload);
    toast.success("Interview status updated successfully!");
    onSubmit({ applicationId: application.id, ...payload });
  } catch (error) {
    console.error("Error updating interview status:", error.response?.data || error.message);
    toast.error(error.response?.data || "Failed to update interview status.");
  }
};

  return (
    <div className="interview-status-form-container">
      <h2>Update Interview Status</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">-- Select Status --</option>
            <option value="1">Selected</option>
            <option value="2">Rejected</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="selectionMessage">Selection Message</label>
          <textarea
            id="selectionMessage"
            value={selectionMessage}
            onChange={(e) => setSelectionMessage(e.target.value)}
            placeholder="Enter the selection message"
            rows="4"
          />
        </div>

        <div className="button-group">
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Processing..." : "Submit"}
          </button>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default InterViewStatusForm;
