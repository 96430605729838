import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageTeam.css";

const ManageTeam = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [form, setForm] = useState({ id: null, name: "", role: "", qualification: "", imageBase64: null, imageContentType: null });
  const [previewImage, setPreviewImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeamMembers();
  }, [currentPage]);

  const fetchTeamMembers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/team-members", {
        params: { page: currentPage - 1, size: 10, keyword: searchTerm.trim() },
      });
      const { teamMembers, totalPages } = response.data;
      setTeamMembers(teamMembers);
      setTotalPages(totalPages || 1);
    } catch (error) {
      toast.error("Error fetching team members.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setForm({ ...form, imageBase64: reader.result.split(",")[1], imageContentType: file.type });
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axiosInstance.put(`/api/team-members/${form.id}`, form);
        toast.success("Team member updated successfully!");
      } else {
        await axiosInstance.post("/api/team-members", form);
        toast.success("Team member added successfully!");
      }
      resetForm();
      fetchTeamMembers();
    } catch (error) {
      toast.error("Error saving team member.");
    }
  };

  const handleEdit = (member) => {
    setForm({
      id: member.id,
      name: member.name,
      role: member.role,
      qualification: member.qualification,
      imageBase64: null,
      imageContentType: null,
    });
    setPreviewImage(member.image ? `data:${member.imageContentType};base64,${member.image}` : null);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this team member?")) {
      try {
        await axiosInstance.delete(`/api/team-members/${id}`);
        toast.success("Team member deleted successfully!");
        fetchTeamMembers();
      } catch (error) {
        toast.error("Error deleting team member.");
      }
    }
  };

  const resetForm = () => {
    setForm({ id: null, name: "", role: "", qualification: "", imageBase64: null, imageContentType: null });
    setPreviewImage(null);
    setIsEditing(false);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchTeamMembers();
  };

  return (
    <div className="manage-team">
      <ToastContainer />
      <h1>Manage Team Members</h1>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search team members..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>
          <FaSearch /> Search
        </button>
      </div>

      <form className="team-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={form.name}
          onChange={handleFormChange}
          required
        />
        <input
          type="text"
          name="role"
          placeholder="Role"
          value={form.role}
          onChange={handleFormChange}
          required
        />
        <input
          type="text"
          name="qualification"
          placeholder="Qualification"
          value={form.qualification}
          onChange={handleFormChange}
        />
        <input type="file" accept="image/*" onChange={handleImageChange} />
        {previewImage && <img src={previewImage} alt="Preview" className="image-preview" />}
        <button type="submit">
          {isEditing ? "Update" : "Add"} Member
        </button>
        {isEditing && <button onClick={resetForm} type="button">Cancel</button>}
      </form>

      {loading ? (
        <div className="page-loading">Loading...</div>
      ) : (
        <table className="team-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Qualification</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.length > 0 ? (
              teamMembers.map((member) => (
                <tr key={member.id}>
                  <td>{member.name}</td>
                  <td>{member.role}</td>
                  <td>{member.qualification}</td>
                  <td>
                    {member.image && <img src={`data:${member.imageContentType};base64,${member.image}`} alt={member.name} className="table-image" />}
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button className="edit-button" onClick={() => handleEdit(member)}>
                        <FaEdit /> Edit
                      </button>
                      <button className="delete-button" onClick={() => handleDelete(member.id)}>
                        <FaTrash /> Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No team members found.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ManageTeam;
