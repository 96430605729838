import React, { useState,useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./axiosInstance";
import "./PaymentDetailsForm.css";
import { SharedStateContext } from "../../SharedStateContext";



const PaymentDetailsForm = ({ application, onSubmit, onCancel }) => {
  const [packageAmount, setPackageAmount] = useState("");
  const [gratuityPercentage, setGratuityPercentage] = useState("");
  const [medicalInsuranceAmount, setMedicalInsuranceAmount] = useState("");
  const [variablePay, setVariablePay] = useState("");
  const [pf, setPF] = useState("");
  const [loading, setLoading] = useState(false);

  const { triggerRefresh } = useContext(SharedStateContext);

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    

    if (
      !packageAmount ||
      !gratuityPercentage ||
      !medicalInsuranceAmount ||
      !variablePay ||
      !pf
    ) {
      toast.error("Please fill out all fields.");
      return;
    }

    setLoading(true);

    try {
      await axiosInstance.post(`/api/applications/accept-offer-letter/${application.id}`, null, {
        params: {
          packageAmount,
          gratuityPercentage,
          medicalInsuranceAmount,
          variablePay,
          pf,
        },
      });

      toast.success("Payment details submitted successfully!");
      triggerRefresh();
      onSubmit({
        applicationId: application.id,
        packageAmount,
        gratuityPercentage,
        medicalInsuranceAmount,
        variablePay,
        pf,
      });
    } catch (error) {
      console.error("Error submitting payment details:", error.response?.data || error.message);
      toast.error(error.response?.data || "Failed to submit payment details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-form-container">
      <h2>Add Payment Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="packageAmount">Package Amount (INR)</label>
          <input
            type="number"
            id="packageAmount"
            value={packageAmount}
            onChange={(e) => setPackageAmount(e.target.value)}
            placeholder="Enter package amount"
          />
        </div>
        <div className="form-group">
          <label htmlFor="gratuityPercentage">Gratuity Percentage (%)</label>
          <input
            type="number"
            id="gratuityPercentage"
            value={gratuityPercentage}
            onChange={(e) => setGratuityPercentage(e.target.value)}
            placeholder="Enter gratuity percentage"
          />
        </div>
        <div className="form-group">
          <label htmlFor="medicalInsuranceAmount">Medical Insurance Amount (INR)</label>
          <input
            type="number"
            id="medicalInsuranceAmount"
            value={medicalInsuranceAmount}
            onChange={(e) => setMedicalInsuranceAmount(e.target.value)}
            placeholder="Enter medical insurance amount"
          />
        </div>
        <div className="form-group">
          <label htmlFor="variablePay">Variable Pay (INR)</label>
          <input
            type="number"
            id="variablePay"
            value={variablePay}
            onChange={(e) => setVariablePay(e.target.value)}
            placeholder="Enter variable pay"
          />
        </div>

        <div className="form-group">
          <label htmlFor="variablePay">PF (INR)</label>
          <input
            type="number"
            id="pf"
            value={pf}
            onChange={(e) => setPF(e.target.value)}
            placeholder="Enter PF amount"
          />
        </div>
        <div className="button-group">
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Processing..." : "Submit"}
          </button>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
  
};

export default PaymentDetailsForm;
