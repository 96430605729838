// ProjectDelivery.jsx
import React from "react";
import "./ProjectDelivery.css";
import {
  FaRegLightbulb,
  FaPencilRuler,
  FaCode,
  FaBug,
  FaCloudUploadAlt,
} from "react-icons/fa";

const projectSteps = [
  {
    icon: <FaRegLightbulb />, 
    title: "Planning",
    description: "We define the project scope, objectives, and requirements to ensure clarity and success.",
    image: "/images/big-images/planning.jpg",
  },
  {
    icon: <FaPencilRuler />, 
    title: "Design",
    description: "Our team creates user-friendly designs and robust system architectures.",
    image: "/images/big-images/degining.jpg",
  },
  {
    icon: <FaCode />, 
    title: "Development",
    description: "Code development with a focus on performance, quality, and scalability.",
    image: "/images/big-images/devlopment.jpg",
  },
  {
    icon: <FaBug />, 
    title: "Testing",
    description: "Rigorous testing ensures the product is bug-free and meets requirements.",
    image: "/images/big-images/testing.jpg",
  },
  {
    icon: <FaCloudUploadAlt />, 
    title: "Deployment",
    description: "Seamless deployment for a smooth and successful launch.",
    image: "/images/big-images/cyber_sec.avif",
  },
];

const ProjectStep = ({ icon, title, description, image, delay }) => (
  <div className="project-step" data-aos="fade-up" data-aos-delay={delay}>
    <div className="step-image">
      <img src={image} alt={title} />
    </div>
    <div className="step-icon">{icon}</div>
    <h4 className="step-title">{title}</h4>
    <p className="step-description">{description}</p>
  </div>
);

const ProjectDelivery = () => (
  <section className="project-delivery">
    <div className="container">
      <h2 className="section-title">Our Project Delivery Process</h2>
      <p className="section-subtitle">
        A structured approach ensures every project is delivered on time, within scope, and with exceptional quality.
      </p>
      <div className="project-steps">
        {projectSteps.map((step, index) => (
          <ProjectStep
            key={index}
            icon={step.icon}
            title={step.title}
            description={step.description}
            image={step.image}
            delay={index * 100}
          />
        ))}
      </div>
    </div>
  </section>
);

export default ProjectDelivery;