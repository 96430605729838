import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * ColumnSelector Component
 * @param {Object} props - Props passed from parent component
 * @param {Array} allColumns - List of all available columns
 * @param {Array} selectedColumns - Currently selected columns
 * @param {Function} setSelectedColumns - Function to update selected columns
 * @param {Function} onApply - Function to apply the column selection
 */
const ColumnSelector = ({ allColumns, selectedColumns, setSelectedColumns, onApply }) => {
  const [tempSelectedColumns, setTempSelectedColumns] = useState([...selectedColumns]);

  /**
   * Toggle column selection
   * @param {string} column - Column key to toggle
   */
  const toggleColumn = (column) => {
    setTempSelectedColumns((prev) =>
      prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
    );
  };

  /**
   * Apply the selected columns
   */
  const handleApply = () => {
    setSelectedColumns(tempSelectedColumns);
    if (onApply) {
      onApply(tempSelectedColumns);
    }
  };

  /**
   * Select All Columns
   */
  const handleSelectAll = () => {
    setTempSelectedColumns([...allColumns]);
  };

  /**
   * Deselect All Columns
   */
  const handleDeselectAll = () => {
    setTempSelectedColumns([]);
  };

  return (
    <div className="column-selector">
      <h4>Select Columns</h4>
      <div className="column-actions">
        <button onClick={handleSelectAll} className="select-all">Select All</button>
        <button onClick={handleDeselectAll} className="deselect-all">Deselect All</button>
      </div>
      <div className="column-list">
        {allColumns.map((col) => (
          <div key={col} className="column-item">
            <input
              type="checkbox"
              id={`col-${col}`}
              checked={tempSelectedColumns.includes(col)}
              onChange={() => toggleColumn(col)}
            />
            <label htmlFor={`col-${col}`}>{col}</label>
          </div>
        ))}
      </div>
      <div className="column-footer">
        <button onClick={handleApply} className="apply-button">Apply</button>
      </div>
    </div>
  );
};

/**
 * PropTypes Validation
 */
ColumnSelector.propTypes = {
  allColumns: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
  onApply: PropTypes.func,
};

export default ColumnSelector;
