import React from "react";
import "./TermsOfUse.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";

const TermsOfUse = () => {
  return (
    <div>
      <Navbar />
      <div className="terms-of-use">
        <h1>Terms of Use</h1>
        <p>
          Welcome to <strong>Encoders Software Pvt. Ltd.</strong> ("Company", "we", "us", or "our"). These Terms of Use govern your access to and use of our website, services, software, and products. By using our services, you agree to abide by these terms. If you do not agree, please refrain from using our services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our services, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you disagree with any part of these terms, you may not access our services.
        </p>

        <h2>2. Business Integrity and Ethical Standards</h2>
        <p>
          At <strong>Encoders Software Pvt. Ltd.</strong>, we prioritize transparency, accountability, and customer satisfaction. We operate under strict ethical guidelines to ensure our clients and partners receive reliable, professional, and secure services.
        </p>

        <h2>3. User Responsibilities</h2>
        <ul>
          <li>Provide accurate and up-to-date information during account creation and transactions.</li>
          <li>Maintain confidentiality of account credentials and passwords.</li>
          <li>Ensure your activities comply with applicable local and international laws.</li>
        </ul>

        <h2>4. Prohibited Activities</h2>
        <p>
          You are expressly prohibited from:
        </p>
        <ul>
          <li>Engaging in unauthorized access to our systems or networks.</li>
          <li>Transmitting malicious software, viruses, or harmful code.</li>
          <li>Misrepresenting your identity or affiliation with another person or organization.</li>
          <li>Using our services for any fraudulent, illegal, or unethical purposes.</li>
        </ul>

        <h2>5. Intellectual Property Rights</h2>
        <p>
          All content, software, logos, designs, and materials on our platform are the intellectual property of <strong>Encoders Software Pvt. Ltd.</strong>. Unauthorized reproduction, distribution, or modification of these materials is strictly prohibited.
        </p>

        <h2>6. Trust and Data Security</h2>
        <p>
          We use industry-standard security practices to protect your data. Our Privacy Policy outlines how we collect, use, and secure your information. By using our services, you consent to these practices.
        </p>
        <p>
          For details, please refer to our <a href="/privacy-policy">Privacy Policy</a>.
        </p>

        <h2>7. Business Continuity and Reliability</h2>
        <p>
          We ensure reliable and uninterrupted access to our services. However, we reserve the right to perform maintenance, updates, or other operational tasks, which may temporarily impact service availability.
        </p>

        <h2>8. Service-Level Agreement (SLA)</h2>
        <p>
          Depending on your service tier, specific SLA terms may apply. Please refer to your service agreement or contact us for more information.
        </p>

        <h2>9. Third-Party Services</h2>
        <p>
          Our services may include integrations or links to third-party platforms. We are not responsible for the content, terms, or practices of these third-party services.
        </p>

        <h2>10. Limitation of Liability</h2>
        <p>
          In no event shall <strong>Encoders Software Pvt. Ltd.</strong> be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or goodwill arising from your use of our services.
        </p>

        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless <strong>Encoders Software Pvt. Ltd.</strong>, its directors, employees, and affiliates from any claims, losses, or damages arising from your violation of these Terms of Use.
        </p>

        <h2>12. Governing Law</h2>
        <p>
          These terms are governed by the laws of India. Any disputes arising from these terms shall be resolved in the jurisdiction of the courts of Ranchi, Jharkhand.
        </p>

        <h2>13. Dispute Resolution</h2>
        <p>
          We prioritize resolving disputes amicably. Please contact us directly to resolve any concerns before initiating legal proceedings.
        </p>

        <h2>14. Changes to These Terms</h2>
        <p>
          We reserve the right to update these Terms of Use periodically. Changes will be effective immediately upon posting. We encourage you to review these terms regularly.
        </p>

        <h2>15. Contact Us</h2>
        <p>
          For any questions or assistance regarding these Terms of Use, please contact our support team:
        </p>
        <p>
          📧 <strong>Email:</strong> <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a><br />
          📞 <strong>Phone:</strong> <a href="tel:+917258803502">+91 7258803502</a>
        </p>
        <p>
          📍 <strong>Encoders Software Pvt. Ltd.</strong><br />
          Oak Forest, Flat No. 8F,<br />
          Pundag Road, Ranchi,<br />
          Jharkhand, 834002, India.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
