import React from "react";
import "./HeroSection.css";

const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-content">
      <img src="/images/big-images/10.jpg" alt="About Encoders Software" className="hero-image" />
     
 
     
      <div className="hero-overlay">
        <h1>Welcome to Encoders Software</h1>
        <p>
          Driving innovation and empowering businesses with cutting-edge technology solutions.
        </p>
        <button className="hero-button">Learn More</button>
      </div>
    </div>
    <div className="hero-info">
      <div className="hero-stats">
        <div className="stat-item">
          <h3>5+</h3>
          <p>Years of Experience</p>
        </div>
        <div className="stat-item">
          <h3>300+</h3>
          <p>Projects Delivered</p>
        </div>
        <div className="stat-item">
          <h3>500+</h3>
          <p>Satisfied Clients</p>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;