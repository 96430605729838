import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Categories.css";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({ name: "", description: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  // Fetch Categories
  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const response = await axiosInstance.get("/api/categories");
      setCategories(response.data);
    } catch (error) {
      toast.error("Failed to fetch categories");
    }
  };

  // Create or Update Category
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCategory) {
        await axiosInstance.put(`/api/categories/${selectedCategory.id}`, form);
        toast.success("Category updated successfully!");
      } else {
        await axiosInstance.post("/api/categories", form);
        toast.success("Category created successfully!");
      }
      setForm({ name: "", description: "" });
      setSelectedCategory(null);
      setIsFormVisible(false);
      loadCategories();
    } catch (error) {
      toast.error("Failed to save category");
    }
  };

  // Delete Category
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axiosInstance.delete(`/api/categories/${id}`);
        toast.success("Category deleted successfully!");
        loadCategories();
      } catch (error) {
        toast.error("Failed to delete category");
      }
    }
  };

  // Edit Category
  const handleEdit = (category) => {
    setSelectedCategory(category);
    setForm({ name: category.name, description: category.description });
    setIsFormVisible(true);
  };

  // Search Categories
  const handleSearch = () => {
    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setCategories(filtered);
  };

  return (
    <div className="categories-container">
      <ToastContainer />

      {/* Header with Search Bar and Add Button */}
      <div className="header">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search categories..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={handleSearch} className="search-btn">
            <FaSearch /> Search
          </button>
        </div>
        <button className="add-btn" onClick={() => setIsFormVisible(true)}>
          <FaPlus /> Add Category
        </button>
      </div>

      {/* Add Category Form Modal */}
      {isFormVisible && (
        <div className="form-modal-overlay">
          <div className="form-modal">
            <div className="form-header">
              <h3>{selectedCategory ? "Update Category" : "Add Category"}</h3>
              <button className="close-btn" onClick={() => setIsFormVisible(false)}>
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <label>Category Name</label>
              <input
                type="text"
                placeholder="Enter Category Name"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                required
              />

              <label>Description</label>
              <textarea
                placeholder="Enter Category Description"
                value={form.description}
                onChange={(e) => setForm({ ...form, description: e.target.value })}
                required
              />

              <div className="form-buttons">
                <button type="submit" className="submit-btn">
                  {selectedCategory ? "Update" : "Add"}
                </button>
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setIsFormVisible(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Category Cards */}
      <div className="category-cards">
        {categories.map((category) => (
          <div key={category.id} className="category-card">
            <h3>{category.name}</h3>
            <p>{category.description}</p>
            <div className="card-actions">
              <button onClick={() => handleEdit(category)} className="edit-btn">
                <FaEdit /> Edit
              </button>
              <button
                onClick={() => handleDelete(category.id)}
                className="delete-btn"
              >
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Categories;