import React, { useState } from "react";
import axiosInstance from "./axiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PayslipForm() {
    const [empNo, setEmpNo] = useState("");
    const [selectedDate, setSelectedDate] = useState(null); // For date picker
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!empNo || !selectedDate) {
            setError("Please fill all the fields");
            return;
        }

        const month = selectedDate.getMonth() + 1; // Months are zero-based
        const year = selectedDate.getFullYear();

        try {
            const response = await axiosInstance.post(`/api/payslip`, {
                empNo,
                month,
                year,
            }, {
                responseType: "blob",
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = `Payslip_${empNo}_${month}_${year}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError(err.response?.data || "Failed to fetch the payslip");
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Generate Payslip</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.field}>
                    <label style={styles.label}>Employee Number:</label>
                    <input
                        type="text"
                        value={empNo}
                        onChange={(e) => setEmpNo(e.target.value)}
                        required
                        style={styles.input}
                        placeholder="Enter Employee Number"
                    />
                </div>

                <div style={styles.field}>
                    <label style={styles.label}>Select Month and Year:</label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        required
                        placeholderText="Select Month and Year"
                        style={styles.input}
                    />
                </div>

                {error && <p style={styles.error}>{error}</p>}

                <button type="submit" style={styles.button}>
                    Generate Payslip
                </button>
            </form>
        </div>
    );
}

const styles = {
    container: {
        maxWidth: "500px",
        margin: "50px auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
    },
    heading: {
        fontSize: "24px",
        color: "#007BFF",
        marginBottom: "20px",
    },
    form: {
        marginTop: "20px",
    },
    field: {
        marginBottom: "15px",
    },
    label: {
        display: "block",
        marginBottom: "5px",
        fontSize: "14px",
        color: "#333",
        fontWeight: "bold",
    },
    input: {
        width: "100%",
        padding: "10px",
        fontSize: "14px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        outline: "none",
        boxSizing: "border-box",
    },
    error: {
        color: "red",
        fontSize: "14px",
        marginBottom: "10px",
    },
    button: {
        width: "100%",
        padding: "12px",
        fontSize: "16px",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
    },
    buttonHover: {
        backgroundColor: "#0056b3",
    },
};

export default PayslipForm;
