import CryptoJS from "crypto-js";

const key = "1234567890123456"; // Same 16-byte key

  const decrypt = (encryptedText) => {
    try {
      // Decode URL-safe Base64
      const base64Decoded = decodeURIComponent(encryptedText);

      // Decrypt the Base64 string
      const bytes = CryptoJS.AES.decrypt(base64Decoded, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
     return decrypted;
    } catch (error) {
      return "Decryption failed!";
    }
  };


  
  export default decrypt;