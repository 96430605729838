import React, { createContext, useState } from "react";

// Create the context
export const SharedStateContext = createContext();

// Create the provider
export const SharedStateProvider = ({ children }) => {
  const [refreshFlag, setRefreshFlag] = useState(false);

  // Function to toggle the flag
  const triggerRefresh = () => {
    setRefreshFlag((prev) => !prev); // Toggle the flag to notify listeners
  };

  return (
    <SharedStateContext.Provider value={{ refreshFlag, triggerRefresh }}>
      {children}
    </SharedStateContext.Provider>
  );
};
