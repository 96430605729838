import React from "react";
import "./ServicesSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faMobileAlt,
  faSms,
  faBriefcase,
  faShieldAlt,
  faChartLine,
  faCloud,
  faUniversity,
  faProjectDiagram,
  faBuilding,
  faCreditCard,
  faUsers,
  faCogs,
  faFileInvoice,
  faDatabase,
  faHandshake
} from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    icon: faGlobe,
    title: "Website Development",
    content: "Responsive websites with hosting, domains, and professional email services tailored for businesses."
  },
  {
    icon: faMobileAlt,
    title: "Mobile App Development",
    content: "Intuitive mobile applications for iOS and Android platforms with seamless user experiences."
  },
  {
    icon: faSms,
    title: "Messaging Services",
    content: "Integrated SMS and WhatsApp APIs to enhance communication with your customers."
  },
  {
    icon: faCreditCard,
    title: "Payment Gateway Integration",
    content: "Secure and robust payment solutions for seamless online transactions."
  },
  {
    icon: faUniversity,
    title: "Educational Solutions",
    content: "Custom IT solutions for colleges, schools, and educational institutions."
  },
  {
    icon: faProjectDiagram,
    title: "Online Project Support",
    content: "Real-time assistance and support for managing academic and professional projects."
  },
  {
    icon: faCloud,
    title: "Cloud Infrastructure Support",
    content: "Scalable and reliable cloud services for storage, computing, and security."
  },
  {
    icon: faShieldAlt,
    title: "Cybersecurity Solutions",
    content: "Comprehensive security measures to protect your data and systems from threats."
  },
  {
    icon: faChartLine,
    title: "Digital Marketing",
    content: "Targeted marketing campaigns to boost your brand visibility and engagement."
  },
  {
    icon: faHandshake,
    title: "CRM Solutions",
    content: "Efficient customer relationship management systems for better engagement and tracking."
  },
  {
    icon: faBuilding,
    title: "Government IT Services",
    content: "IT services customized to meet the needs of government institutions and e-governance initiatives."
  },
  {
    icon: faCogs,
    title: "ERP Solutions",
    content: "Enterprise resource planning systems to streamline your business operations."
  },
  {
    icon: faFileInvoice,
    title: "SaaS Products",
    content: "Software as a Service solutions to cater to industry-specific needs with efficiency."
  },
  {
    icon: faDatabase,
    title: "Data Analytics",
    content: "Unlock insights and make data-driven decisions with our analytics tools."
  },
  {
    icon: faUsers,
    title: "Staff Augmentation",
    content: "Skilled resources to enhance your workforce and productivity."
  }
];

const ServiceItem = ({ icon, title, content, delay }) => (
  <div className="service-item" data-aos="fade-up" data-aos-delay={delay}>
    <FontAwesomeIcon icon={icon} className="service-icon" />
    <h4 className="service-title">{title}</h4>
    <p className="service-content">{content}</p>
  </div>
);

const ServicesSection = () => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">Our Comprehensive Solutions</h2>
      <p className="section-subtitle">
        Explore a wide array of IT services tailored to meet the evolving needs of businesses, educational institutions, and government agencies.
      </p>
      <div className="services-list">
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            icon={service.icon}
            title={service.title}
            content={service.content}
            delay={index * 100}
          />
        ))}
      </div>
    </div>
  </section>
);

export default ServicesSection;