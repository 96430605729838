import React from "react";
import "./TeamSection.css";

const TeamMember = ({ name, role, qualification, image, delay }) => (
  <div className="team-member" data-aos="fade-up" data-aos-delay={delay}>
    <div className="team-image-container">
      <img src={image} alt={name} className="team-image" />
    </div>
    <h4 className="team-name">{name}</h4>
    <p className="team-role">{role}</p>
    <p className="team-qualification">{qualification}</p>
  </div>
);

const TeamSection = ({ teamMembers }) => (
  <section className="team">
    <div className="container">
      <h2 className="section-title">Meet Our Team</h2>
      <p className="section-subtitle">
        Our dedicated team of professionals is committed to delivering exceptional results and driving innovation.
      </p>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <TeamMember
            key={member.id}
            name={member.name}
            role={member.role}
            qualification={member.qualification}
            image={`data:image/jpeg;base64,${member.image}`}
            delay={index * 100}
          />
        ))}
      </div>
    </div>
  </section>
);

export default TeamSection;
