import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // Check for tokens in localStorage
  
  useEffect(() => {

    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // Logout handler
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    sessionStorage.clear();
    localStorage.clear();
    setIsLoggedIn(false); // Update state
    navigate("/"); // Redirect to home page
  };

  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar bg-white py-2 shadow-sm">
        <div className="container  justify-content-end align-items-center">
          {/* Right-aligned Contact Info */}
          <div className="contact-info  align-items-center">
            <div className="me-4  align-items-center">
              {/* Phone Icon */}
              <i className="fa fa-phone me-2" style={{ color: "blue" }}></i>
              <a
                href="tel:+917258803502"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                +91-7258803502
              </a>
            </div>
            <div className="d-flex align-items-center">
              {/* Email Icon */}
              <i className="fa fa-envelope me-2" style={{ color: "blue" }}></i>
              <a
                href="mailto:support@encoderssoftware.com"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                support@encoderssoftware.com
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          {/* Logo */}
          <Link to="/" className="navbar-brand">
            <img src="/logo.png" alt="Encoders Software Pvt Ltd" height="50" />
          </Link>

          {/* Collapse Button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link" style={{ color: "#333" }}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/about"
                  className="nav-link"
                  style={{ color: "#333" }}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="nav-link"
                  style={{ color: "#333" }}
                >
                  Contact Us
                </Link>
              </li>


              

              <li className="nav-item">
                <Link
                  to="/Careers"
                  className="nav-link"
                  style={{ color: "#333" }}
                >
                 Careers
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/BlogsUsers"
                  className="nav-link"
                  style={{ color: "#333" }}
                >
                 Blogs
                </Link>
              </li>

              


              {isLoggedIn && (
  <li className="nav-item">
    <Link to="/HomePage" className="nav-link" style={{ color: "#333" }}>
      Menu
    </Link>
  </li>
)}

              {/* Conditional Login/Logout Button */}
              <li className="nav-item ms-3">
                {isLoggedIn ? (
                  <button
                    onClick={handleLogout}
                    className="btn btn-outline-danger  align-items-center"
                    style={{ border: "none" }}
                  >
                    <i className="fa fa-sign-out me-2"></i> Logout
                  </button>
                ) : (
                  <Link
                    to="/LoginSignup"
                    className="btn btn-outline-primary  align-items-center"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-user me-2"></i> Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
