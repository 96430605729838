import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // Axios instance for API calls
import { FaSearch, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserManage.css";

const UserManage = () => {
  const [users, setUsers] = useState([]); // List of users
  const [roles, setRoles] = useState([]); // List of roles
  const [editingUserId, setEditingUserId] = useState(null); // User being edited
  const [newRoleId, setNewRoleId] = useState(""); // New role ID for the user
  const [searchKeyword, setSearchKeyword] = useState(""); // Search keyword
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [pageSize, setPageSize] = useState(5); // Page size


  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    status: "",
  });
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [currentPage, pageSize, filters]);

  // ✅ Fetch Users
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/users/view-all", {
        params: {
          page: currentPage - 1,
          size: pageSize,
          keyword: searchKeyword.trim(),
          firstName: filters.firstName.trim(),
          lastName: filters.lastName.trim(),
          email: filters.email.trim(),
          role: filters.role.trim(),
          status: filters.status.trim(),
        },
      });
      setUsers(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error("Failed to fetch users.");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Fetch Roles
  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get("/api/users/roles");
      setRoles(response.data);
    } catch (error) {
      toast.error("Failed to fetch roles.");
    }
  };

  // ✅ Handle Header Filter Change
  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
    setCurrentPage(1); // Reset to first page on filter change
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [currentPage, pageSize]);

  
  

  // Update user's role
  const updateUserRole = async (userId) => {
    if (!newRoleId) {
      toast.error("Please select a role.");
      return;
    }
    try {
      await axiosInstance.put(`/api/users/${userId}/update-role`, { roleId: parseInt(newRoleId) });
      toast.success("User role updated successfully.");
      setEditingUserId(null); // Exit edit mode
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error("Failed to update user role.");
    }
  };

  // Toggle user's active/inactive status
  const toggleUserStatus = async (userId, currentStatus) => {
    try {
      await axiosInstance.put(`/api/users/${userId}/toggle-status`);
      toast.success(`User status updated to ${currentStatus === 1 ? "active" : "Inactive"}.`);
      
      // Instantly update the user's status in the state
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, active: !user.active } : user
        )
      );
    } catch (error) {
      toast.error("Failed to update user status.");
    }
  };

  // Handle search action
  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page
    fetchUsers();
  };

  return (
    <div className="user-manage">
    <ToastContainer />
    <h1 className="header">User Management</h1>

    {/* Search and Controls */}
    <div className="controls">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name, email..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="small-input"
        />
        <button onClick={fetchUsers} className="search-button">
          <FaSearch /> Search
        </button>
      </div>
      <select
        className="page-size-selector"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
      </select>
    </div>

    {/* User Table */}
    <table className="user-table">
      <thead>
        <tr>
          <th>
            First Name
            <input
              type="text"
              placeholder="Filter"
              value={filters.firstName}
              onChange={(e) => handleFilterChange("firstName", e.target.value)}
              className="header-filter"
            />
          </th>
          <th>
            Last Name
            <input
              type="text"
              placeholder="Filter"
              value={filters.lastName}
              onChange={(e) => handleFilterChange("lastName", e.target.value)}
              className="header-filter"
            />
          </th>
          <th>
            Email
            <input
              type="text"
              placeholder="Filter"
              value={filters.email}
              onChange={(e) => handleFilterChange("email", e.target.value)}
              className="header-filter"
            />
          </th>
          <th>
            Role
            <select
              value={filters.role}
              onChange={(e) => handleFilterChange("role", e.target.value)}
              className="header-filter"
            >
              <option value="">All</option>
              {roles.map((role) => (
                <option key={role.id} value={role.name}>
                  {role.name}
                </option>
              ))}
            </select>
          </th>
          <th>
            Status
            <select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              className="header-filter"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {users.map((user) => (
            <tr key={user.id}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>
                {editingUserId === user.id ? (
                  <select
                    value={newRoleId}
                    onChange={(e) => setNewRoleId(e.target.value)}
                    className="small-input"
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  user.roleName || "No Role Assigned"
                )}
              </td>
              <td>{user.active ? "Active" : "Inactive"}</td>
              <td>
                {editingUserId === user.id ? (
                  <>
                    <button onClick={() => updateUserRole(user.id)} className="save-button">
                      <FaSave /> Save
                    </button>
                    <button onClick={() => setEditingUserId(null)} className="cancel-button">
                      <FaTimes /> Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setEditingUserId(user.id);
                        setNewRoleId(user.roleId || "");
                      }}
                      className="edit-button"
                    >
                      <FaEdit /> Edit Role
                    </button>
                    <button
                onClick={() => toggleUserStatus(user.id, user.active ? 1 : 0)}
                className={`toggle-button ${user.active ? "deactivate" : "activate"}`}
              >
                {user.active ? "Deactivate" : "Activate"}
              </button>
                    
                  </>
                )}
              </td>
          </tr>
        ))}
      </tbody>
    </table>

    {/* Pagination */}
    <div className="pagination">
      <button
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  </div>
  );
};

export default UserManage;
