import React, { useState } from "react";
import "./AboutCompany.css";

const AboutCompany = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="about-company">
      <div className="container">
        <h2 className="section-title">Welcome to Encoders Software</h2>
        <p className="section-content">
          At Encoders Software Private Limited, we specialize in delivering cost-effective IT solutions
          for businesses, educational institutions, and government organizations. With over 5 years of
          expertise, we provide a range of services, including website development, mobile app solutions,
          and customized business applications.
        </p>
        {isExpanded && (
          <div className="expanded-content">
            <p>
              Our mission is to deliver excellence through innovation and dedication. We have worked with
              hundreds of clients across various industries, offering tailored software solutions that
              drive growth and efficiency. Our expertise extends to ERP solutions, CRM development,
              cloud integrations, and more.
            </p>
            <p>
              Our team consists of passionate professionals who believe in continuous improvement and
              delivering measurable results. From ideation to deployment, we focus on every detail to
              ensure client satisfaction.
            </p>
          </div>
        )}
        <button className="read-more-button" onClick={toggleReadMore}>
          {isExpanded ? "Read Less" : "Read More"}
        </button>
        <div className="about-highlights">
          <div className="highlight-item">
            <h3>5+</h3>
            <p>Years in Business</p>
          </div>
          <div className="highlight-item">
            <h3>20+</h3>
            <p>Projects Completed</p>
          </div>
          <div className="highlight-item">
            <h3>20+</h3>
            <p>Satisfied Clients</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;