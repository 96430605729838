import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Line, Pie, Doughnut, Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './GraphDisplay.css';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
);

/**
 * GraphDisplay Component
 * @param {Object} props - Props passed from parent component
 * @param {Object} graphData - Data for the graphs
 * @param {Array} selectedGraphs - List of selected graph types
 */
const GraphDisplay = ({ graphData, selectedGraphs }) => {
  if (!graphData || selectedGraphs.length === 0) {
    return (
      <div className="graph-display-empty">
        No graph data available. Please select graph types and load data.
      </div>
    );
  }

  return (
    <div className="graph-display-container">
      {selectedGraphs.map((type) => (
        <div key={type} className="graph-item">
          <h3>{type} Graph</h3>
          <div className="graph-canvas">
            {type === 'Bar' && <Bar data={graphData} options={{ responsive: true }} />}
            {type === 'Line' && <Line data={graphData} options={{ responsive: true }} />}
            {type === 'Pie' && <Pie data={graphData} options={{ responsive: true }} />}
            {type === 'Doughnut' && <Doughnut data={graphData} options={{ responsive: true }} />}
            {type === 'Radar' && <Radar data={graphData} options={{ responsive: true }} />}
          </div>
        </div>
      ))}
    </div>
  );
};

/**
 * PropTypes Validation
 */
GraphDisplay.propTypes = {
  graphData: PropTypes.object.isRequired,
  selectedGraphs: PropTypes.array.isRequired,
};

export default GraphDisplay;
