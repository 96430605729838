import React from "react";
import "./ProgressTimeline.css";

const ProgressTimeline = ({ currentStep }) => {
  const steps = [
    "Initiated",
    "Resume Accepted/Rejected",
    "Selected",
    "Offer Letter Generated",
  ];

  return (
    <div className="timeline-container">
      {steps.map((step, index) => (
        <div key={index} className="timeline-step">
          {/* Connector line */}
          {index !== 0 && (
            <div
              className={`timeline-connector ${
                index <= currentStep ? "highlighted" : ""
              }`}
            ></div>
          )}

          {/* Step circle with animation */}
          <div
            className={`timeline-circle ${
              index <= currentStep ? "highlighted bounce" : ""
            }`}
            title={step}
          ></div>

          {/* Step label */}
          <div
            className={`timeline-label ${
              index <= currentStep ? "highlighted" : ""
            }`}
          >
            {step}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressTimeline;
