import CryptoJS from "crypto-js";



const SECRET_KEY = '1234567890123456'; // 16-byte key

// Function to encrypt payload
export const encrypt = (data) => {
  try {
    const plaintext = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(
      plaintext,
      CryptoJS.enc.Utf8.parse(SECRET_KEY),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    // Ensure proper Base64 encoding
    const base64Encoded = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return base64Encoded;
  } catch (error) {
    console.error('Encryption Error:', error.message);
    return null;
  }
};


export default encrypt;
