import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.css";
import config from "../../include/config";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage("Email is required.");
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await fetch(
        `${config.BASE_URL}/api/auth/forgot-password?email=${email}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.ok) {
        setSuccessMessage("Password reset link sent to your email.");
        setErrorMessage("");
        toast.success("Password reset link sent successfully!");
      } else {
        const error = await response.json();
        setErrorMessage(error.error || "Unable to send reset link.");
        toast.error(error.error || "Unable to send reset link.");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div>
      <div className="center-container">
        <div className="form-container">
          <div className="form-header">
            <h1>Forgot Password</h1>
            <p>Enter your email address to receive a reset link.</p>
          </div>
          <div className="input-group">
            <span className="icon">
              <FaEnvelope />
            </span>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errorMessage && <p className="field-error">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <button
            className="submit-btn"
            onClick={handleForgotPassword}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Sending..." : "Send Reset Link"}
          </button>
          {loading && <div className="loader"></div>} {/* Loader UI */}
          <div className="signup-link">
            <a href="/LoginSignup">Back to Login</a>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
